<template>
  <div class="productsList">
    <div class="d-flex justify-space-between align-start">
      <h3 class="mt-0 text-primary">Bestellung</h3>
      <OrderProductModal
        mode="new"
        class="ml-2">
        + Hilfsmittel hinzufügen
      </OrderProductModal>
    </div>
    <v-divider
      class="c-contentCard__divider -fullWidth mt-4"
    ></v-divider>
    <v-data-table
      class="c-contentCard__table -fullWidth"
      :headers="headers"
      :items="order.orderproducts"
      disable-pagination
      hide-default-footer
      hide-default-header
    >
      <template
        slot="item"
        slot-scope="props">
        <tr>
          <td class="pr-0 py-3">
            <v-img
              max-height="50"
              max-width="50"
              :src="props.item.product.image"
            ></v-img>
          </td>
          <td class="py-3 font-size-18">
            <header>
              <span class="font-weight-normal d-inline-block mb-0">{{ props.item.product.name }}</span>
              <span class="ml-2 font-weight-light">{{ props.item.start | date }} - {{ props.item.end | date }}</span>
              <OrderProductModal
                mode="update"
                :orderProduct="props.item">
                <v-icon x-small>edit</v-icon>
              </OrderProductModal>
            </header>
            <div
              v-if="props.item.additional_information"
              class="font-size-14"
            >
              <div v-if="props.item.additional_information.seatWidth">Sitzbreite: {{ props.item.additional_information.seatWidth }}</div>
              <div v-if="props.item.additional_information.legSupport">Beinstütze hochschwenkbar: {{ props.item.additional_information.legSupport }}</div>
            </div>
          </td>
          <td class="py-3 font-size-18 text-right pr-0"> {{ props.item.total | euro }}</td>
          <td class="py-3 truncate text-right">
            <OrderProductDeleteModal
              v-if="orderProductsCount > 1"
              class="ml-2"
              :order-id="order.id"
              :order-product-id="props.item.id"
            >
              <v-icon x-small>delete</v-icon>
            </OrderProductDeleteModal>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OrderProductModal from '@/components/orderDetails/OrderProductModal.vue'
import OrderProductDeleteModal from '@/components/orderDetails/OrderProductDeleteModal.vue'

export default {
  data() {
    return {
      headers: [
        {
          text: 'Produktbild',
          value: 'image',
        },
        {
          text: 'Produkt',
          value: 'name',
        },
        {
          text: 'Preis',
          value: 'total',
        },
        {
          text: 'Aktionen',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      order: 'order/order',
    }),
    orderProductsCount() {
      return this.order.orderproducts.length
    },
  },
  components: {
    OrderProductModal,
    OrderProductDeleteModal,
  },
}
</script>

<style scoped>
.truncate {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.productsList tr:hover{
    background: inherit !important;
}
</style>
