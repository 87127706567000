<template>
  <v-container>
    <v-row class="d-flex justify-space-between align-center mb-2 font-size-18 font-weight-bold">
      <span>Gesamt</span>
      <span>{{ order.total | euro }}</span>
    </v-row>
    <v-row class="d-flex justify-space-between align-center mb-2 font-size-18 grey--text">
      <span>davon Lieferung</span>
      <span>{{ order.delivery_costs | euro }}</span>
    </v-row>
    <v-row
      v-for="(item, index) in order.vatTypes"
      :key="index"
      class="d-flex justify-space-between align-center mb-3 font-size-18 grey--text"
    >
      <span>davon USt. ({{ index }}%)</span>
      <span>{{ item | euro }}</span>
    </v-row>
    <v-row
      v-if="order.alreadyPaid"
      class="d-flex justify-space-between align-center mb-2 font-size-18 grey--text"
    >
      <span class="green--text">Bereits gezahlt</span>
      <span class="text-right">
        <span class="green--text">{{ order.alreadyPaid / 100 | euro }}</span>
        <div v-if="order.paymentFees">
          + {{order.paymentFees / 100 || euro }} Kredit Karten<br>Gebühren
        </div>
      </span>
    </v-row>
    <v-divider
      class="c-contentCard__divider -fullWidthInsideComponent -dashed mb-4"
    ></v-divider>
    <v-row class="d-flex justify-space-between align-center mb-2 font-size-18 grey--text">
      <span>Kaution</span>
      <span>{{ order.deposit | euro }}</span>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      order: 'order/order',
    }),
  },
}
</script>
