export default {
  login(state, loginData) {
    state.token = loginData.access_token
    state.isSuperAdmin = loginData.isSuperAdmin
    state.partner_me = loginData.partner_me
    state.partner_mh = loginData.partner_mh
    state.company_id = loginData.company_id
    state.companyName = loginData.company_name
    state.name = loginData.name
    state.email = loginData.email
  },
  logout(state) {
    state.token = null
    state.isSuperAdmin = null
    state.partner_me = null
    state.partner_mh = null
    state.company_id = null
    state.companyName = null
    state.company_id = null
    state.name = null
    state.email = null
  },
  setToken(state, token) {
    state.token = token
  },
  setLoading(state, loading) {
    state.loading = loading
  },
}
