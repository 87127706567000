<template>
  <v-card>
    <v-card-text>
      <NoteModal
        :type="type"
        :foreignId="foreignId"
        @success="$emit('success')">
        Neue Notiz
      </NoteModal>
      <v-timeline
        dense
        align-top
        v-if="items && items.length > 0">
        <v-timeline-item
          v-for="(item, i) in items"
          :key="i"
          color="red lighten-2"
          icon="notes"
          fill-dot>
          <v-card
            color="indigo"
            dark>
            <v-card-title>{{ item.user_name }}</v-card-title>
            <v-card-text class="white text--primary pt-2">
              <p v-html="item.text" />
              <small>{{ item.created_at | datetime }}</small>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
      <v-col
        class="text-center"
        v-else
        cols="12">
        Keine Notizen vorhanden
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script>
import NoteModal from '@/components/NoteModal.vue'

export default {
  props: {
    items: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    type: {
      type: String,
      required: true,
    },
    foreignId: {
      type: Number,
      required: true,
    },
  },
  components: {
    NoteModal,
  },
}
</script>
