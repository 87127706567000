<template>
  <v-row>
    <v-col>
      <v-card height="100%">
        <v-card-title class="c-contentCard__title -small pb-1 font-weight-regular">Auftrag</v-card-title>
        <v-card-text class="c-contentCard__text -big">
          #{{  order.id }}
        </v-card-text>
      </v-card>
    </v-col>
    <v-col>
      <v-card height="100%">
        <v-card-title class="c-contentCard__title -small pb-1 font-weight-regular">Erstellt am</v-card-title>
        <v-card-text class="c-contentCard__text -big">
          {{  order.created_at | datetime }}
        </v-card-text>
      </v-card>
    </v-col>
    <v-col>
      <v-card height="100%">
        <v-card-title class="c-contentCard__title -small  pb-1 font-weight-regular">Kunde</v-card-title>
        <v-card-text class="c-contentCard__text -big">
          {{  order.b_firstname }} {{  order.b_lastname }}
          <div
            v-if="order.order_agent_id"
            class="font-size-14"
          >
            über <span class="font-weight-bold">{{ order.orderAgent.company_name }}</span>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col>
      <v-card height="100%">
        <v-card-title class="c-contentCard__title -small  pb-1 font-weight-regular">Gesamtbetrag (inkl. Lieferung)</v-card-title>
        <v-card-text class="c-contentCard__text -big">
          {{ order.total | euro }}
          <span
            :class="[order.total === order.alreadyPaid ? 'green--text' : 'red--text']"
            v-if="order.alreadyPaid"
          >
            ({{ order.alreadyPaid / 100 | euro }})
          </span>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col>
      <v-card height="100%">
        <v-card-title class="c-contentCard__title -small  pb-1 font-weight-regular">Lieferart</v-card-title>
        <v-card-text class="c-contentCard__text -big">
          <v-icon v-if="delivery.icon">
            {{ delivery.icon }}
          </v-icon>
          {{  delivery.text }}
          <CustomerAddressModal type="delivery">
            <v-btn
              x-small
              class="ml-2">
              <v-icon x-small>
                edit
              </v-icon>
            </v-btn>
          </CustomerAddressModal>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomerAddressModal from '@/components/orderDetails/CustomerAddressModal.vue'

export default {
  computed: {
    ...mapGetters({
      order: 'order/order',
    }),
    delivery() {
      if (this.order.delivery === this.$constants.DELIVERY_TYPES.own.VALUE) {
        return {
          text: this.$constants.SUBDELIVERY_TYPES[this.order.subdelivery].TEXT,
          icon: this.$constants.SUBDELIVERY_TYPES[this.order.subdelivery].ICON,
        }
      }
      return {
        text: this.$constants.DELIVERY_TYPES[this.order.delivery].TEXT,
        icon: this.$constants.DELIVERY_TYPES[this.order.delivery].ICON,
      }
    },
  },
  components: {
    CustomerAddressModal,
  },
}
</script>
