export default {
  company: (state) => state.company,
  companies(state) {
    return [...state.companies].sort((companyA, companyB) => {
      if (companyA.deactivated_at && companyB.deactivated_at) {
        return companyA.id - companyB.id
      }
      if (companyA.deactivated_at) {
        return 1
      }
      if (companyB.deactivated_at) {
        return -1
      }
      return companyA.id - companyB.id
    })
  },
  loading: (state) => state.loading,
}
