import AuthAPI from '@/api/auth'

export default {
  login({ commit }, model) {
    commit('setLoading', true)
    return AuthAPI.login(model).then((response) => {
      commit('login', response)
    }).finally(() => {
      commit('setLoading', false)
    })
  },
  logout({ commit }) {
    commit('setLoading', true)
    return AuthAPI.logout().then(() => {
      commit('logout')
    }).finally(() => {
      commit('setLoading', false)
    })
  },
}
