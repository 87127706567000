<template>
  <v-container fluid>
    <v-col
      v-if="loading"
      cols="12">
      <div class="text-center">
        <v-progress-circular
          color="primary"
          indeterminate
        />
      </div>
    </v-col>
    <v-card
      v-else>
      <v-card-title>
        <slot name="buttons" />
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Partner suchen"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="companies"
        :search="search"
        :footer-props="{
          'items-per-page-options': itemsPerPage,
        }"
        class="elevation-1"
      >
        <template
          slot="item"
          slot-scope="props">
          <tr
            class="-clickable"
            :class="{
              'grey lighten-2': props.item.deactivated_at,
            }"
            @click="selectCompany(props.item.id)">
            <td>
              {{ props.item.name }}
              <div
                v-if="props.item.deactivated_at"
                class="red--text">
                Deaktiviert
              </div>
            </td>
            <td>
              {{ props.item.contact_name }}
              <template v-if="props.item.contact_mail"><br><a :href="'mailto:' + props.item.contact_mail">{{ props.item.contact_mail }}</a></template>
              <template v-if="props.item.contact_tel"><br>{{ props.item.contact_tel }}</template>
            </td>
            <td>
              {{ props.item.street }},
              {{ props.item.zip }}
              {{ props.item.city }}
            </td>
            <td>
              <v-btn
                color="primary"
                small>
                <v-icon>search</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    selectCompany: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Name',
          sortable: true,
          value: 'name',
          sort: (nameA, nameB) => {
            const a = this.companies.find((c) => c.name === nameA)
            const b = this.companies.find((c) => c.name === nameB)
            if (a.deactivated_at && !b.deactivated_at) return 1
            if (!a.deactivated_at && b.deactivated_at) return -1
            return a.name < b.name ? -1 : 1
          },
        },
        {
          text: 'Ansprechpartner',
          sortable: false,
        },
        {
          text: 'Adresse',
          sortable: false,
        },
        {
          text: 'Aktionen',
          value: 'name',
        },
      ],
      itemsPerPage: [100, 200, -1],
    }
  },
  created() {
    this.fetchCompanies()
  },
  computed: {
    ...mapGetters({
      loading: 'company/loading',
      companies: 'company/companies',
      isSuperAdmin: 'auth/isSuperAdmin',
      company_id: 'auth/company_id',
    }),
  },
  methods: {
    ...mapActions('company', ['fetchCompanies']),
  },
}
</script>
