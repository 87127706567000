<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="500">
    <template v-slot:activator="{ on }">
      <span
        v-on="on">
        <slot></slot>
      </span>
    </template>
    <v-form
      v-model="isFormValid"
      @submit.prevent="save">
      <v-card>
        <v-card-title
          class="headline grey lighten-2">
          Auftrag stornieren
        </v-card-title>
        <v-card-text>
          <p>
            Bitte wählen Sie aus, warum der Auftrag storniert wird:
          </p>
          <v-radio-group
            v-model="reasonId"
            class="s-reasons">
            <v-radio
              :value="1"
              class="mb-3">
              <template v-slot:label>
                {{ reasons[1][language] }}
              </template>
            </v-radio>
            <v-radio
              :value="2"
              class="mb-3">
              <template v-slot:label>
                {{ reasons[2][language] }}
              </template>
            </v-radio>
            <v-text-field
              v-if="reasonId === 2"
              v-model="reasonTexts[2]"
              :rules="[rules.required]"
              label="Telefonnummer"
              ref="reasonText-2"
              class="s-reasonText" />
            <v-radio
              :value="3"
              class="mb-3">
              <template v-slot:label>
                {{ reasons[3][language] }}
              </template>
            </v-radio>
            <v-text-field
              v-if="reasonId === 3"
              v-model="reasonTexts[3]"
              :rules="[rules.required]"
              label="Telefonnummer"
              ref="reasonText-3"
              class="s-reasonText" />
            <v-radio
              :value="4"
              class="mb-3">
              <template v-slot:label>
                {{ reasons[4][language] }}
              </template>
            </v-radio>
            <v-text-field
              v-if="reasonId === 4"
              v-model="reasonTexts[4]"
              :rules="[rules.required]"
              label="Telefonnummer"
              ref="reasonText-4"
              class="s-reasonText" />
            <v-radio
              :value="5"
              class="mb-3">
              <template v-slot:label>
                {{ reasons[5][language] }}
              </template>
            </v-radio>
            <v-text-field
              v-if="reasonId === 5"
              v-model="reasonTexts[5]"
              :rules="[rules.required]"
              label="Auftragsnummer"
              ref="reasonText-5"
              class="s-reasonText" />
            <v-radio
              :value="6"
              class="mb-3">
              <template v-slot:label>
                {{ reasons[6][language] }}
              </template>
            </v-radio>
            <v-radio
              :value="7"
              class="mb-3">
              <template v-slot:label>
                {{ reasons[7][language] }}
              </template>
            </v-radio>
            <v-radio
              :value="8"
              class="mb-3">
              <template v-slot:label>
                Sonstiger Grund
              </template>
            </v-radio>
            <v-textarea
              v-if="reasonId === 8"
              v-model="reasonTexts[8]"
              :rules="[rules.required]"
              :label="`Stornierungsbegründung für den Kunden (${language == 'de' ? 'Deutsch' : 'Englisch'})`"
              ref="reasonText-7"
              class="s-reasonText" />
          </v-radio-group>
          <v-divider /><br />
          <p>
            Der Kunde erhält eine Stornierungs-E-Mail mit dem ausgewählten Grund.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :disabled="isButtonDisabled"
            :loading="loading"
            type="submit">
            Auftrag stornieren
          </v-btn>
          <v-btn
            @click="dialog = false">
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import OrdersAPI from '@/api/orders'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      dialog: false,
      isFormValid: false,
      loading: false,
      notes: null,
      reasonId: null,
      reasons: {
        1: {
          de: 'Leider haben wir kein geeignetes Hilfsmittel für den gewünschten Mietzeitraum auf Lager.',
          en: 'Unfortunately, we do not have a suitable product in stock for the desired rental period.',
        },
        2: {
          de: 'Die Bereitstellung und Lieferung kann nicht so kurzfristig erfolgen. Bitte rufen Sie uns an, falls für Sie ein späterer Liefertermin in Frage käme:',
          en: 'The preparation and delivery cannot be made at such short notice. Please call us if a later delivery date would be possible for you:',
        },
        3: {
          de: 'Die Lieferung kann nicht so kurzfristig erfolgen. Jedoch wäre eine Abholung zum gewünschten Mietbeginn möglich. Bitte rufen Sie uns an, falls Sie das Hilfsmittel selbst abholen möchten:',
          en: 'The delivery cannot be made at such short notice. However, a pick-up at the desired rental start would be possible. Please call us if you would like to pick up the product yourself:',
        },
        4: {
          de: 'Das Hilfsmittel kann nicht in der gewünschten Ausführung bereitgestellt werden. Bitte rufen Sie uns an, damit wir ggf. eine andere Ausführung anbieten können:',
          en: 'The product cannot be provided in the desired version. Please call us so that we can offer you another version if necessary:',
        },
        5: {
          de: 'Der Auftrag wurde doppelt erfasst. Ihr Hilfmittel wird unter dieser Auftragsnummer bearbeitet:',
          en: 'The order was entered twice. Your product will be processed under this order number:',
        },
        6: {
          de: 'Es steht kein Hilfsmittel in der gewünschten Größe bereit.',
          en: 'There is no product available in the desired size.',
        },
        7: {
          de: 'Auf Ihre Veranlassung hin wurde die Bestellung storniert.',
          en: 'The order was canceled at your request.',
        },
        8: {
          // free text entry
          de: '',
          en: '',
        },
      },
      reasonTexts: {
        2: '',
        3: '',
        4: '',
        5: '',
      },
      rules: {
        required: (value) => !!value || 'Erforderlich',
      },
    }
  },
  mounted() {
    this.reasonTexts[2] = this.order.company.tel
    this.reasonTexts[3] = this.order.company.tel
    this.reasonTexts[4] = this.order.company.tel
  },
  computed: {
    ...mapGetters({
      order: 'order/order',
    }),
    isButtonDisabled() {
      return this.loading || !this.isFormValid
    },
    language() {
      return this.order.language === 'de' ? 'de' : 'en'
    },
    reason() {
      return this.reasonId ? this.reasons[this.reasonId][this.language] : 'Kein Grund angegeben'
    },
  },
  watch: {
    reasonId() {
      this.$nextTick(() => {
        if (this.$refs[`reasonText-${this.reasonId}`]) {
          this.$refs[`reasonText-${this.reasonId}`].$el.querySelector('input,textarea').focus()
        }
      })
    },
  },
  methods: {
    ...mapActions('order', ['fetchOrder']),
    save() {
      this.loading = true
      OrdersAPI.updateStorno(this.order.id, {
        cancel: true,
        notes: this.notes,
        reason: this.reason,
        reasonText: this.reasonTexts[this.reasonId] || '',
        sendAlternatives: this.reasonId !== 5 && this.reasonId !== 7,
      }).then(() => {
        this.dialog = false
        this.fetchOrder(this.$route.params.id)
      }).catch(() => {
        this.$toasted.error('Der Auftrag konnte leider nicht storniert werden. Bitte wenden Sie sich an den Support.')
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.s-reasonText {
  margin: -6px 8px 16px 32px;
}

.v-radio.v-item--active::v-deep .v-label {
  color: rgb(0, 0, 0);
}
</style>
