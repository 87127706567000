import base from '@/api/base'

export default {
  create(type, foreignId, text) {
    return base.post(`/me/backend/ordernotes/${type}/${foreignId}`, { text })
  },
  getNotes(type, foreignId) {
    return base.get(`/me/backend/notes/${type}/${foreignId}`)
  },

}
