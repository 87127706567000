import ordersAPI from '@/api/orders'

export default {
  fetchOrder({ commit }, id) {
    return ordersAPI.getOrder(id).then((response) => {
      commit('setOrder', response)
      commit('setLoading', false)
    })
  },
  setLoading({ commit }, isLoading) {
    commit('setLoading', isLoading)
  },
}
