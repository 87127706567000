<template>
  <v-dialog
    v-model="dialog"
    width="500">
    <template v-slot:activator="{ on }">
      <span
        v-on="on">
        <slot></slot>
      </span>
    </template>

    <v-form @submit.prevent="save">
      <v-card>
        <v-card-title
          class="headline grey lighten-2">
          Interne Notiz hinzufügen
        </v-card-title>
        <v-card-text>
          <v-textarea
            autofocus
            outlined
            auto-grow
            v-model="text"
            label="Notiz">
          </v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            type="submit"
            color="success"
            :loading="loading"
            :disabled="loading">
            Speichern
          </v-btn>
          <v-btn
            type="button"
            color="error"
            @click="dialog = false"
            :loading="loading"
            :disabled="loading">
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import NotesAPI from '@/api/notes'
import { mapActions } from 'vuex'

export default {
  props: {
    type: {
      type: Number,
      required: true,
    },
    foreignId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      dialog: false,
      text: null,
    }
  },
  methods: {
    ...mapActions('note', ['fetchNotes']),
    save() {
      if (!this.text || this.text.trim().length < 1) {
        this.$toasted.error('Das Notizfeld muss ausgefüllt sein')
        return
      }

      this.loading = true
      NotesAPI.create(this.type, this.foreignId, this.text).then(() => {
        this.fetchNotes({ type: this.type, id: this.foreignId })
        this.dialog = false
      }).catch(() => {
        this.$toasted.error('Something is wrong')
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
