<template>
  <tr>
    <td>
      <v-textarea
        label="Postleitzahlen"
        placeholder="10001, 10002, 10003, ..."
        :value="item.zip"
        @change="updateZipCodes($event)"
      />
    </td>
    <td
      class="vertical-top"
      style="width:150px;">
      <v-text-field
        label="Lieferpreis"
        append-icon="euro"
        type="number"
        min="0"
        step="0.01"
        :rules="[rules.requiredDeliveryPrice(item.zip)]"
        :value="item.delivery_price"
        @change="updateZipPrice($event)"
      />
    </td>
    <td
      style="width:30px;"
      class="vertical-top pt-5">
      <v-btn
        v-if="countOfZips > 1 && index !== countOfZips - 1"
        @click="removeZip(item)"
        color="red"
        dark
        x-small>
        <v-icon small>close</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    countOfZips: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      rules: {
        requiredDeliveryPrice(zip) {
          if (zip === '') {
            return true
          }
          return (value) => !!value || 'Erforderlich'
        },
      },
    }
  },
  methods: {
    updateZipCodes(value) {
      const index = this.index
      this.$store.commit('branchOffice/setZipCodesForSelectedBranchOffice', { index, zip: value })
    },
    updateZipPrice(value) {
      const index = this.index
      this.$store.commit('branchOffice/setZipPriceForSelectedBranchOffice', { index, delivery_price: value })
    },
    removeZip(zip) {
      this.$store.commit('branchOffice/removeZipInSelectedBranchOffice', zip)
    },
  },
}
</script>

<style scoped>
.vertical-top{
    vertical-align: top;
}
</style>
