import store from '../store/index'

export const downloadFile = (data, filename, type) => {
  const newBlob = new Blob([data], { type })

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob)
    return
  }

  const objectURL = window.URL.createObjectURL(newBlob)
  const link = document.createElement('a')
  link.href = objectURL
  link.download = filename
  link.click()
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(objectURL)
  }, 100)
}

export const downloadPDF = (response, filename) => {
  downloadFile(response, filename, 'application/pdf')
}

export const downloadXLSX = (response, filename) => {
  downloadFile(response, filename, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
}

export const isSuperAdmin = () => store.getters['auth/isSuperAdmin']
