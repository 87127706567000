<template>
  <v-app>
    <component :is="layout" />
  </v-app>
</template>

<script>

import Main from './layouts/Main.vue'
import Plain from './layouts/Plain.vue'

export default {
  computed: {
    layout() {
      switch (this.$route.meta.layout) {
        case 'plain':
          return Plain
        case 'main':
        default:
          return Main
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/index.scss';
</style>
