<template>
  <v-container fluid>
    <v-form
      v-model="isFormValid"
      @submit.prevent="calculate">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <h4>Lieferkosten</h4>
              <v-text-field
                label="Postleitzahl oder genaue Adresse*"
                v-model="zip"
                :rules="[rules.required]"
              />
              <v-btn
                class="mt-2"
                :disabled="isButtonDisabled"
                :loading="checking"
                color="primary"
                type="submit">
                Berechnen
              </v-btn>
              <div
                v-if="calculatedPrice"
                class="mt-2"
              >
                {{ calculatedPrice | euro }} für eine Richtung<br>
                {{ 2 * calculatedPrice | euro }} für beide Richtungen
              </div>
            </v-col>
            <v-col cols="9">
              <h4>Preisübersicht</h4>
              <v-data-table
                :headers="headers"
                :items="products"
                :loading="loading"
                disable-pagination
                hide-default-footer
                class="elevation-1"
              >
                <template
                  slot="item"
                  slot-scope="props">
                  <tr>
                    <td>{{ props.item.name }}</td>
                    <td>{{ props.item.pricePerDay | euro }}</td>
                    <td>{{ props.item.additionalPrice | euro }}</td>
                    <td>{{ props.item.deposit | euro }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import ToolsAPI from '@/api/tools'

export default {
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Mietpreis / Tag',
          value: 'pricePerDay',
        },
        {
          text: 'Bereitstellung',
          value: 'additionalPrice',
        },
        {
          text: 'Kaution',
          value: 'deposit',
        },
      ],
      isFormValid: false,
      checking: false,
      loading: false,
      zip: '',
      calculatedPrice: null,
      rules: {
        required: (value) => !!value || 'Erforderlich',
      },
      products: [],
    }
  },
  created() {
    this.fetch()
  },
  computed: {
    isButtonDisabled() {
      return !this.isFormValid
    },
  },
  methods: {
    fetch() {
      this.loading = true
      ToolsAPI.getRentProductsForCompany().then((response) => {
        this.products = response
        this.loading = false
      })
    },

    calculate() {
      this.checking = true
      ToolsAPI.calculateDeliveryCosts({ zip: this.zip }).then((response) => {
        this.calculatedPrice = response
      }).catch((response) => {
        this.calculatedPrice = null
        response
          .json()
          .then((result) => {
            if (result[0] !== undefined && result[0].code !== undefined) {
              this.$toasted.error(result[0].code)
            }
          })
      }).finally(() => {
        this.checking = false
      })
    },
  },

}
</script>

<style scoped>

</style>
