const dirtyStateWarningMessage = 'Ihre Eingaben wurden noch nicht gespeichert. Sind Sie sicher, dass Sie diese Seite verlassen wollen?'

export default {
  data() {
    return {
      dirtyStateWarningEnabled: false,
      dirtyState: false,
      dirtyStateIsEventHandlerAttached: false,
      dirtyStateWatchlist: [],
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.dirtyStateWarningEnabled || !this.dirtyState) {
      next()
      return
    }
    if (window.confirm(dirtyStateWarningMessage)) {
      next()
    } else {
      next(false)
    }
  },
  beforeDestroy() {
    // we do it like this because while an event is bound to this,
    // the browser's cache is disabled
    if (this.dirtyStateIsEventHandlerAttached) {
      window.removeEventListener('beforeunload', this.dirtyStateUnloadHandler)
    }
  },
  methods: {
    dirtyStateUnset() {
      this.dirtyState = false
      this.dirtyStateWindowEventUnbind()
    },
    dirtyStateSet() {
      this.dirtyState = true
      this.dirtyStateWindowEventBind()
    },
    dirtyStateUnloadHandler(event) {
      if (!this.dirtyStateWarningEnabled || !this.dirtyState) {
        return
      }
      event.returnValue = dirtyStateWarningMessage
    },
    dirtyStateWatch(data) {
      this.dirtyStateWarningEnabled = true
      if (this.dirtyStateWatchlist.includes(data)) {
        // if we were already watching this,
        // the module probably updated the server side data,
        // which means we should be clean
        this.$nextTick(() => { this.dirtyStateUnset() })
        return
      }
      this.$watch(data, () => { this.dirtyStateSet() }, { deep: true })
      this.dirtyStateWatchlist.push(data)
    },
    dirtyStateWindowEventBind() {
      if (!this.dirtyStateIsEventHandlerAttached) {
        window.addEventListener('beforeunload', this.dirtyStateUnloadHandler)
        this.dirtyStateIsEventHandlerAttached = true
      }
    },
    dirtyStateWindowEventUnbind() {
      if (this.dirtyStateIsEventHandlerAttached) {
        window.removeEventListener('beforeunload', this.dirtyStateUnloadHandler)
        this.dirtyStateIsEventHandlerAttached = false
      }
    },
  },
}
