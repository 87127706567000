<template>
  <v-row>
    <v-col cols="6">
      <v-row>
        <v-col cols="6">
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="fromDate"
                label="Zeitraum von"
                prepend-icon="event"
                readonly
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="from"
              @input="menu1 = false"
            />
          </v-menu>
        </v-col>
        <v-col cols="6">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="untilDate"
                label="Zeitraum bis"
                prepend-icon="event"
                readonly
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="until"
              @input="menu2 = false"
            />
          </v-menu>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="6">
      <v-autocomplete
        v-model="selectedPartners"
        :loading="loading"
        :items="partners"
        label="Wähle die gewünschten Partner aus"
        multiple
        item-text="name"
        item-value="id"
        chips
        deletable-chips
      ></v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import format from 'date-fns/format'
import CompanyAPI from '@/api/companies'

export default {
  data() {
    return {
      partners: [],
      selectedPartners: [],
      loading: false,
      menu1: false,
      menu2: false,
      from: null,
      until: null,
      on: null,
    }
  },
  created() {
    this.loadPartners()
    const date = new Date()
    this.from = format(new Date(date.getFullYear(), date.getMonth(), 1), 'YYYY-MM-DD')
    this.until = format(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'YYYY-MM-DD')
  },
  computed: {
    untilDate: {
      set(value) {
        this.until = format(value, 'YYYY-MM-DD')
      },
      get() {
        return format(this.until, 'DD.MM.YYYY')
      },
    },
    fromDate: {
      set(value) {
        this.from = format(value, 'YYYY-MM-DD')
      },
      get() {
        return format(this.from, 'DD.MM.YYYY')
      },
    },
  },
  watch: {
    from() {
      this.$emit('update', this.createFilterData())
    },
    until() {
      this.$emit('update', this.createFilterData())
    },
    selectedPartners() {
      this.$emit('update', this.createFilterData())
    },
  },
  methods: {
    createFilterData() {
      const partnerIds = []
      if (this.partners) {
        this.partners.forEach((e1) => {
          this.selectedPartners.forEach((e2) => {
            if (e1.name === e2) {
              partnerIds.push(e1.value)
            }
          })
        })
      }

      return {
        from: this.from,
        until: this.until,
        partners: partnerIds,
      }
    },
    loadPartners() {
      this.loading = true
      CompanyAPI.getCompanies().then((response) => {
        this.partners = response ? response.map((item) => ({
          value: item.id,
          name: item.name,
        })) : []
        this.loading = false
      })
    },
  },
}
</script>
