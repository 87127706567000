<template>
  <v-form
    v-model="isFormValid"
    @submit.prevent="save">
    <v-row justify="center">
      <v-col cols="4">
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="appointmentDate"
              label="Date"
              prepend-icon="event"
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="appointmentDay"
            @input="menu1 = false"

          />
        </v-menu>
      </v-col>
      <v-col cols="4">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="appointmentTime"
              label="Time"
              prepend-icon="access_time"
              readonly
              v-on="on"
            />
          </template>
          <v-time-picker
            v-model="appointmentHour"
            format="24hr"
            @input="menu2 = false"
          />
        </v-menu>
      </v-col>
      <v-col
        cols="4"
        class="d-flex justify-center align-center">
        <v-btn
          color="success"
          :disabled="isButtonDisabled"
          type="submit">
          Speichern
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import format from 'date-fns/format'
import { mapActions, mapGetters } from 'vuex'
import OrdersAPI from '@/api/orders'

export default {
  data() {
    return {
      isFormValid: false,
      loading: false,
      menu1: false,
      menu2: false,
      appointmentDay: null,
      appointmentHour: null,
    }
  },
  created() {
    if (this.order.appointment[0]) {
      this.appointmentDay = this.order.appointment[0]
    }
    if (this.order.appointment[1]) {
      this.appointmentHour = format(new Date(`${this.order.appointment[0]} ${this.order.appointment[1]}`), 'HH:mm')
    }
  },
  computed: {
    ...mapGetters({
      order: 'order/order',
    }),
    appointmentDate: {
      set(value) {
        this.appointmentDay = format(value, 'YYYY-MM-DD')
      },
      get() {
        if (!this.appointmentDay) {
          return null
        }
        return format(this.appointmentDay, 'DD.MM.YYYY')
      },
    },
    appointmentTime: {
      set(value) {
        this.appointmentHour = format(value, 'HH:mm')
      },
      get() {
        return this.appointmentHour
      },
    },
    isButtonDisabled() {
      if (!this.appointmentDay) {
        return true
      }
      return this.loading || !this.isFormValid
    },
  },
  methods: {
    ...mapActions('order', ['fetchOrder']),
    save() {
      this.loading = true
      OrdersAPI.updateAppointment(this.order.id, { form_appointment_date: this.appointmentDay, form_appointment_time: this.appointmentHour }).then(() => {
        this.dialog = false
        this.fetchOrder(this.$route.params.id)
      }).catch(() => {
        this.$toasted.error('Hier stimmt etwas nicht. Bitte wenden Sie sich an den Support.')
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
