export default {
  token: (state) => state.token,
  isSuperAdmin: (state) => state.isSuperAdmin,
  partner_me: (state) => state.partner_me,
  partner_mh: (state) => state.partner_mh,
  name: (state) => state.name,
  email: (state) => state.email,
  companyName: (state) => state.companyName,
  company_id: (state) => state.company_id,
  loading: (state) => state.loading,
}
