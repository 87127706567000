<template>
  <v-row>
    <v-col
      cols="12"
      class="pt-0">
      <h3>Filialverwaltung</h3>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="branchOffices"
        :loading="loading"
        :items-per-page="-1"
        class="elevation-1 mb-4">
        <template
          slot="item"
          slot-scope="props">
          <tr
            class="-clickable"
            @click="selectBranch(props.item)">
            <td>{{ props.item.street }}</td>
            <td>{{ props.item.zip }}</td>
            <td>{{ props.item.city }}</td>
            <td>
              <div
                class="d-inline-block mr-2 mb-2"
                v-for="(paymentType, index) in props.item.paymentMethods.pick_up.paymentTypes"
                :key="index">
                <v-chip>
                  {{ paymentName(paymentType) }}
                </v-chip>
              </div>
            </td>
            <td>
              <v-btn
                @click="selectBranch(props.item)"
                color="primary"
                dark
                small>
                <v-icon>search</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12">
      <v-btn
        @click="selectBranch(null)"
        color="success">
        Neue Filiale
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  data() {
    return {
      headers: [
        {
          text: 'Straße',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Postleitzahl',
          sortable: true,
          value: 'website',
        },
        {
          text: 'Ort',
          sortable: true,
          value: 'address',
        },
        {
          text: 'Zahlungsmöglichkeiten im Fachgeschäft',
          value: 'pick_up.paymentTypes',
        },
        {
          text: 'Aktionen',
          value: 'name',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      branchOffices: 'branchOffice/branchOffices',
      loading: 'branchOffice/loading',
    }),
  },
  methods: {
    ...mapMutations({
      setSelectedBranchOffice: 'branchOffice/setSelectedBranchOffice',
    }),
    selectBranch(branchOffice) {
      if (branchOffice) {
        this.setSelectedBranchOffice(branchOffice.id)
      } else {
        this.setSelectedBranchOffice('new')
      }
      this.$emit('selectBranch', branchOffice)
    },
    paymentName(value) {
      return this.$constants.PAYMENT_METHODS.find((item) => item.VALUE === value).TEXT
    },
  },
}
</script>
