<template>
  <v-dialog
    v-model="dialog"
    width="500">
    <template v-slot:activator="{ on }">
      <v-text-field
        v-on="on"
        v-model="appointmentDate"
        label="Datum"
      />
    </template>

    <v-card>
      <v-card-title
        class="headline grey lighten-2">
        Termin ändern
      </v-card-title>
      <v-card-text>
        <AppointmentSelect
          @selected="selectedDate = $event"
          :availabilities="availabilities"
        />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          @click="save">
          Auswählen
        </v-btn>
        <v-btn
          color="error"
          @click="dialog = false">
          Abbrechen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import format from 'date-fns/format'

export default {
  props: {
    date: {
      type: String,
      required: true,
    },
    availabilities: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      selectedDate: null,
    }
  },
  computed: {
    appointmentDate() {
      return format(this.date, 'DD.MM.YYYY HH:mm')
    },
  },
  methods: {
    save() {
      this.dialog = false
      this.$emit('selected', format(this.selectedDate, 'YYYY-MM-DD HH:mm:ss'))
    },
  },
}
</script>

<style lang="scss" scoped>
    div.v-dialog__container {
        width: 100%;
    }
</style>

<style lang="scss">
    div .theme--light.v-input:not(.v-input--is-disabled) input,
    div .theme--light.v-input:not(.v-input--is-disabled) textarea {
        font-size: 18px;
    }
</style>
