import base from '@/api/base'

export default {
  getRentProductsForCompany() {
    return base.get('/me/backend/tools/get-products-for-company')
  },

  calculateDeliveryCosts(data) {
    return base.post('/me/backend/tools/calculate-delivery-costs', data)
  },
}
