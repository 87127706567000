import notesAPI from '@/api/notes'

export default {
  fetchNotes({ commit }, { type, id }) {
    commit('setLoading', true)
    notesAPI.getNotes(type, id).then((response) => {
      commit('setNotes', response.notes)
      commit('setLoading', false)
    })
  },
}
