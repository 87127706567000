<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <v-dialog
    v-model="open"
    width="500">
    <v-form @submit.prevent="save">
      <v-card>
        <v-card-title class="headline grey lighten-2">Kundenberatungsgespräch</v-card-title>
        <v-card-text>
          <template v-if="step === 1">Wurde ein Produkt verkauft?</template>
          <template v-if="step === 2">
            <v-autocomplete
              v-if="sold"
              v-model="productModel"
              :loading="loading"
              :items="availableProductModels"
              cache-items
              hide-no-data
              hide-details
              label="Welches Produkt wurde verkauft?"
            ></v-autocomplete>
            <v-textarea
              v-model="note"
              label="Ihre Notizen">
            </v-textarea>
          </template>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <template v-if="step === 1">
            <v-btn
              type="button"
              color="success"
              @click.prevent="choice(true)">
              Ja
            </v-btn>
            <v-btn
              type="button"
              color="error"
              @click.prevent="choice(false)">
              Nein
            </v-btn>
          </template>
          <template v-if="step === 2">
            <v-btn
              type="button"
              color="error"
              @click.prevent="step--">
              Zurück
            </v-btn>
            <v-btn
              type="submit"
              color="success">
              Speichern
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import ProductsAPI from '@/api/products'

export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      step: 1,
      note: null,
      sold: false,
      loading: false,
      products: null,
      productModel: null,
    }
  },
  created() {
    this.loadProducts()
  },
  computed: {
    availableProductModels() {
      if (!this.products) {
        return []
      }

      const models = this.products.map((item) => ({
        value: item.id,
        text: `${item.manufacturer} ${item.name}`,
      }))
      models.push({ value: 0, text: 'Sonstiges' })
      return models
    },
  },
  methods: {
    choice(sold) {
      this.sold = sold
      this.step++
    },
    loadProducts() {
      this.loading = true
      ProductsAPI.getProductModels().then((response) => {
        this.products = Object.values(response.product_models)
        this.loading = false
      })
    },
    save() {
      this.$emit('success', {
        note: this.note,
        product_model: this.productModel,
      })
    },
  },
}
</script>
