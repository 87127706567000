import base from '@/api/base'

export default {
  getProductModels() {
    return base.get('/me/backend/product-models')
  },
  getManufacturers() {
    return base.get('/me/backend/manufacturers')
  },
  getRentProducts() {
    return base.get('/me/backend/products/rent-products')
  },
  storeProduct(product) {
    return base.post('/me/backend/product-models', product)
  },
  updateProduct(product) {
    return base.post(`/me/backend/product-models/${product.id}`, product)
  },
}
