import base from '@/api/base'

export default {
  getUsers(companyId) {
    return base.get(`/me/backend/companies/${companyId}/users`)
  },
  create(model) {
    return base.post(`/me/backend/companies/${model.company_id}/users`, model)
  },
  update(model) {
    return base.put(`/me/backend/companies/${model.company_id}/users/${model.id}`, model)
  },
  delete(model) {
    return base.delete(`/me/backend/companies/${model.company_id}/users/${model.id}`)
  },
  resetPassword(model) {
    return base.put(`/me/backend/companies/${model.company_id}/users/${model.id}/reset-password`, model)
  },
}
