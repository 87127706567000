/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Router from 'vue-router'
import Store from '@/store'

// Routes
import paths from './paths'

function route(path, view, name, meta, children) {
  return {
    name,
    meta: meta || {},
    path,
    children: children || [],
    // eslint-disable-next-line
    component: (resovle) => import(/* webpackChunkName: "view-[request]" */
      `@/views/${view}.vue`
    ).then(resovle),
  }
}

Vue.use(Router)

// Create a new router
const router = new Router({
  mode: 'history',
  routes: paths.map((path) => route(path.path, path.view, path.name, path.meta, path.children)).concat([
    { path: '*', redirect: '/dashboard' },
  ]),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  Store.commit('app/setTitle', null)
  next()
})

export default router
