<template>
  <v-stepper
    :value="this.order.status"
    non-linear
    class="mb-2">
    <v-stepper-header>
      <div
        v-for="(status, index) in $constants.ORDER_STATUSES"
        :key="index">
        <v-stepper-step
          :complete="order.status >= status.VALUE"
          :editable="isEditable(status)"
          :step="status.STEP"
          @click="changeStatus(status, $event)">
          {{ status.TEXT }}
        </v-stepper-step>
        <v-divider />
      </div>
    </v-stepper-header>
    <StatusReadyModal />
    <AppointmentTimeModal
      ref="appointmentTimeModal"
      @saved="openStatusReadyModal" />
  </v-stepper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import AppointmentTimeModal from '@/components/orderDetails/AppointmentTimeModal.vue'
import StatusReadyModal from '@/components/orderDetails/StatusReadyModal.vue'
import OrdersAPI from '@/api/orders'

export default {
  computed: {
    ...mapGetters({
      order: 'order/order',
    }),
    orderIsDelivery() {
      return (
        this.order.delivery === 'own'
        && (
          this.order.subdelivery === 'own_own'
          || this.order.subdelivery === 'own_pickup'
        )
      )
    },
  },
  methods: {
    ...mapActions('order', ['fetchOrder']),
    changeStatus(status, $event) {
      $event.preventDefault()
      if (!this.isEditable(status)) {
        return
      }
      if (status.VALUE === this.$constants.ORDER_STATUSES.STATUS_READY.VALUE) {
        if (this.orderIsDelivery && !this.order.appointment) {
          this.$refs.appointmentTimeModal.open()
        } else {
          this.openStatusReadyModal()
        }
      } else {
        OrdersAPI.setStatus(this.order.id, status.VALUE).then(() => {
          this.$toasted.success('Der Auftragsstatus wurde erfolgreich geändert!')
        }).catch(() => {
          this.$toasted.error('Hier stimmt etwas nicht. Bitte versuchen Sie es später erneut.')
        }).finally(() => {
          this.fetchOrder(this.$route.params.id)
        })
      }
    },
    isEditable(status) {
      if (this.order.status < status.VALUE && !this.prevStepIsEditable(status) && !this.order.storno) {
        return true
      }
      return false
    },
    openStatusReadyModal() {
      this.$store.commit('order/setReadyStatusModalIsOpen', true)
    },
    prevStepIsEditable(currentStatus) {
      let prevStatus = null
      let prevStep = currentStatus.STEP - 1
      if (prevStep < 1) {
        prevStep = 1
      }
      Object.values(this.$constants.ORDER_STATUSES).forEach((status) => {
        if (prevStep === status.STEP) {
          prevStatus = status
        }
      })
      if (prevStatus.VALUE > this.order.status) {
        return true
      }
      return false
    },
  },
  components: {
    AppointmentTimeModal,
    StatusReadyModal,
  },
}
</script>
