<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      width="500">
      <template v-slot:activator="{ on }">
        <v-btn
          v-if="showButtonToDeactivate"
          v-on="on"
          color="error">
          Partner deaktivieren
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">Partner deaktivieren</v-card-title>
        <v-card-text>
          Möchten Sie diesen Partner wirklich deaktivieren? Dadurch wird er Kunden nicht mehr zur Auswahl angeboten.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            :loading="loading"
            color="error"
            type="button"
            @click="confirm">
            Ja, deaktivieren
          </v-btn>
          <v-btn
            @click="dialog = false">
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CompanyAPI from '@/api/companies'

export default {
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      dialog: false,
      showButtonToDeactivate: true,
    }
  },
  methods: {
    confirm() {
      this.loading = true
      CompanyAPI.deactivate(this.company).then(() => {
        this.$toasted.success(`${this.company.name} wurde deaktiviert`)
        this.showButtonToDeactivate = false
        this.$emit('success')
      }).catch(() => {
        this.$toasted.error(`${this.company.name} konnte leider nicht deaktiviert werden. Bitte wenden Sie sich an den Support.`)
      }).finally(() => {
        this.loading = false
        this.dialog = false
      })
    },
  },
}
</script>
