import base from '@/api/base'

export default {
  getRatings() {
    return base.get('/me/backend/ratings')
  },
  getRating(id) {
    return base.get(`/me/backend/ratings/${id}`)
  },
  create(data) {
    return base.post('/me/backend/ratings', data)
  },
  update(data) {
    return base.put(`/me/backend/ratings/${data.id}`, data)
  },
  delete(data) {
    return base.delete(`/me/backend/ratings/${data.id}`)
  },
}
