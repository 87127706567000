import base from '@/api/base'

export default {
  getPrices(id) {
    return base.get(`/me/backend/prices/${id}`)
  },
  updatePrices(id, prices) {
    return base.post(`/me/backend/prices/${id}`, prices)
  },
  exportAllPrices() {
    return base.getFile('/me/backend/prices/export')
  },
}
