import BranchOfficesAPI from '@/api/branchOffices'

export default {
  fetchBranchOffices({ commit }, companyId) {
    commit('setLoading', true)
    return BranchOfficesAPI.getBranchOfficesByCompanyId(companyId).then((response) => {
      commit('setBranchOffices', response)
      commit('setLoading', false)
    })
  },
}
