<template>
  <material-card color="primary">
    <template v-slot:header>
      <v-container class="py-0">
        <v-row
          justify="space-between">
          <h4
            class="title font-weight-light mb-2">
            Neue Terminanfragen
          </h4>
          <router-link
            class="px-4 mr-n4"
            to="/appointments/consulting">
            <v-icon
              small
              color="white">
              arrow_forward
            </v-icon>
          </router-link>
        </v-row>
      </v-container>
    </template>
    <template v-if="loading">
      <v-col
        class="text-center"
        v-if="loading"
        cols="12">
        <v-progress-circular
          color="primary"
          indeterminate/>
      </v-col>
    </template>
    <template v-else>
      <v-alert
        v-if="consultingAppointments.length === 0"
        color="info"
        text>
        Aktuell gibt es keine aktiven Terminanfragen
      </v-alert>
      <v-data-table
        v-else
        :customSort="customSort"
        :headers="headers"
        :items="consultingAppointments"
        hide-default-footer>
        <template
          slot="headerCell"
          slot-scope="{ header }">
          <span
            class="font-weight-light text-primary"
            v-text="header.text"
          />
        </template>
        <template
          slot="item"
          slot-scope="{ item }"
        >
          <tr
            class="linked-tr"
            @click="openAppointment(item)">
            <td>{{ item.name }}</td>
            <td>{{ item.date | datetime }}</td>
            <td>{{ item.status | consultingAppointmentStatus }}</td>
          </tr>
        </template>
      </v-data-table>
    </template>
  </material-card>
</template>

<script>
import compareDesc from 'date-fns/compare_desc'

export default {
  props: {
    consultingAppointments: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          sortable: false,
          text: 'Name',
          value: 'name',
        },
        {
          sortable: true,
          text: 'Termin',
          value: 'date',
        },
        {
          sortable: true,
          text: 'Status',
          value: 'status',
        },
      ],
      items: [],
    }
  },
  methods: {
    openAppointment(recall) {
      this.$router.push(`/appointments/consulting/${recall.id}`)
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index === 'date') {
          if (!isDesc) {
            return compareDesc(a.date, b.date)
          }
          return compareDesc(b.date, a.date)
        }
        let value = a[index].localeCompare(b[index])
        if (isDesc) {
          value *= -1
        }
        return value
      })
      return items
    },
  },
}
</script>
<style lang="scss">
@import "src/styles/material-dashboard/variables";
.v-card .v-offset .v-card--material__header.v-card {
  padding: 0 $padding-general;
  h4, a{
    padding: $padding-general 0;
  }
}
</style>
