<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="500">
    <template v-slot:activator="{ on }">
      <span
        v-on="on">
        <slot></slot>
      </span>
    </template>
    <v-form
      v-model="isFormValid"
      @submit.prevent="save">
      <v-card>
        <v-card-title
          class="headline grey lighten-2">
          {{ title }}
        </v-card-title>
        <v-card-text>
          <v-row
            v-if="type === 'delivery'">
            <v-col
              cols="12">
              <v-select
                v-model="address.delivery"
                :items="deliveryTypes"
                item-text="text"
                item-value="value"
                label="Liefertyp ändern"
              ></v-select>
            </v-col>
          </v-row>
          <v-row
            v-if="type === 'delivery' && addressInputsVisible">
            <v-col
              cols="12">
              <v-select
                v-model="address.subdelivery"
                :items="subdeliveryTypes"
                item-text="text"
                item-value="value"
                label="Lieferdetails"
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="addressInputsVisible">
            <v-col cols="6">
              <v-text-field
                dense
                label="Vorname*"
                v-model="address.firstname"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                label="Nachname*"
                v-model="address.lastname"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                label="Firmenname"
                v-model="address.companyname"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field

                dense
                label="Straße*"
                v-model="address.street"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                dense
                label="PLZ*"
                v-model="address.zip"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="9">
              <v-text-field
                dense
                label="Stadt*"
                v-model="address.city"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                label="Telefon"
                v-model="address.phone"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                label="E-Mail*"
                v-model="address.email"
                :rules="[rules.email]"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :disabled="isButtonDisabled"
            :loading="loading"
            type="submit">
            Speichern
          </v-btn>
          <v-btn
            @click="dialog = false">
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import OrdersAPI from '@/api/orders'

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      address: {},
      isFormValid: false,
      loading: false,
      dialog: false,
      rules: {
        required: (value) => !!value || 'Erforderlich',
        email: (value) => value.includes('@') || 'Ungültige E-Mail',
      },
    }
  },
  created() {
    if (this.type === 'billing') {
      this.address = {
        companyname: this.order.b_companyname,
        firstname: this.order.b_firstname,
        lastname: this.order.b_lastname,
        street: this.order.b_street,
        zip: this.order.b_zip,
        city: this.order.b_city,
        phone: this.order.b_phone,
        email: this.order.b_email,
      }
    } else {
      this.address = {
        companyname: this.order.companyname,
        firstname: this.order.firstname,
        lastname: this.order.lastname,
        street: this.order.street,
        zip: this.order.zip,
        city: this.order.city,
        phone: this.order.phone,
        email: this.order.email,
        delivery: this.order.delivery,
        subdelivery: this.order.subdelivery,
      }
    }
  },
  computed: {
    ...mapGetters({
      order: 'order/order',
    }),
    deliveryTypes() {
      return [
        {
          value: this.$constants.DELIVERY_TYPES.pickup.VALUE,
          text: 'Abholung durch Kunden',
        },
        {
          value: this.$constants.DELIVERY_TYPES.own.VALUE,
          text: 'Lieferung durch Sanitätshaus',
        },
      ]
    },
    subdeliveryTypes() {
      return [
        {
          value: this.$constants.SUBDELIVERY_TYPES.own_own.VALUE,
          text: 'Sanitätshaus liefert und holt zurück',
        },
        {
          value: this.$constants.SUBDELIVERY_TYPES.own_pickup.VALUE,
          text: 'Sanitätshaus liefert, Kunde bringt zurück',
        },
        {
          value: this.$constants.SUBDELIVERY_TYPES.pickup_own.VALUE,
          text: 'Kunde holt ab, Sanitätshaus holt zurück',
        },
      ]
    },
    isButtonDisabled() {
      return this.loading || !this.isFormValid
    },
    addressInputsVisible() {
      if (this.type === 'delivery' && this.address.delivery === 'pickup') {
        return false
      }
      return true
    },
    title() {
      if (this.type === 'delivery') {
        return 'Lieferung bearbeiten'
      }
      return 'Rechnungsadresse bearbeiten'
    },
  },
  methods: {
    ...mapActions('order', ['fetchOrder']),
    save() {
      this.loading = true
      const apiCall = this.type === 'billing'
        ? OrdersAPI.updateBillingAddress(this.order.id, this.address)
        : OrdersAPI.updateDeliveryAddress(this.order.id, this.address)
      apiCall.then(() => {
        this.$toasted.success('Die Adresse wurde aktualisiert')
        this.dialog = false
        this.fetchOrder(this.$route.params.id)
      }).catch(() => {
        this.$toasted.error('Hier stimmt etwas nicht. Bitte versuchen Sie es später erneut.')
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
