<template>
  <div>
    <h3 class="mb-1 text-primary">Kundenwunsch</h3>
    <div
      v-if="order.customernotes"
      class="font-size-18"
    >
      {{ order.customernotes }}
    </div>
    <div
      v-else
      class="font-size-18"
    >
      Es gibt keine Besonderheiten zu diesem Auftrag.
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      order: 'order/order',
    }),
  },
}
</script>
