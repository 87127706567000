export default {
  setCompany(state, company) {
    state.company = company
  },
  setCompanies(state, companies) {
    state.companies = companies
  },
  setLoading(state, loading) {
    state.loading = loading
  },
}
