<template>
  <material-card
    color="primary"
    title="Neue Rückrufanfragen">
    <template v-if="loading">
      <v-col
        class="text-center"
        v-if="loading"
        cols="12">
        <v-progress-circular
          color="primary"
          indeterminate/>
      </v-col>
    </template>
    <template v-else>
      <v-alert
        v-if="recalls.length === 0"
        color="info"
        text>
        Aktuell gibt es keine aktiven Rückrufanfragen
      </v-alert>
      <v-data-table
        v-else
        :customSort="customSort"
        :headers="headers"
        :items="recalls"
        hide-default-footer>
        <template
          slot="headerCell"
          slot-scope="{header}"
        >
          <span
            class="font-weight-light text-primary"
            v-text="header.text"
          />
        </template>
        <template
          slot="item"
          slot-scope="{ item }"
        >
          <tr
            class="linked-tr"
            @click="openRecall(item)">
            <td>{{ item.name }}</td>
            <td>{{ item.time | daytime }}</td>
            <td>{{ item.phone }}</td>
            <td>{{ item.status | recallStatus }}</td>
          </tr>
        </template>
      </v-data-table>
    </template>
  </material-card>
</template>

<script>
import compareDesc from 'date-fns/compare_desc'

export default {
  props: {
    recalls: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          sortable: true,
          text: 'Name',
          value: 'name',
        },
        {
          sortable: true,
          text: 'Zeitraum',
          value: 'time',
        },
        {
          sortable: true,
          text: 'Telefonnummer',
          value: 'phone',
        },
        {
          sortable: true,
          text: 'Status',
          value: 'status',
        },
      ],
    }
  },
  methods: {
    openRecall(recall) {
      this.$router.push(`/appointments/recalls/${recall.id}`)
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index === 'date' || index === 'created_at') {
          if (!isDesc) {
            return compareDesc(a.date, b.date)
          }
          return compareDesc(b.date, a.date)
        }

        let value = a[index].localeCompare(b[index])
        if (isDesc) {
          value *= -1
        }
        return value
      })
      return items
    },
  },
}
</script>
