<template>
  <v-text-field
    class="mr-4 mt-2 purple-input"
    label="Auftrags-Suche nach Kunde / ID"
    hide-details
    color="purple"
    v-model="query"
    prepend-inner-icon="search"
    @input="type"
  />
</template>

<script>
import _ from 'lodash'

export default {
  data() {
    return {
      query: null,
    }
  },
  watch: {
    $route: {
      handler() {
        this.query = this.$route.query.keyword
      },
      immediate: true,
    },
  },
  methods: {
    type: _.debounce(function (value) {
      this.$router.push({
        path: '/results',
        query: {
          keyword: value,
        },
      })
    }, 250),
  },
}
</script>
