<template>
  <v-dialog
    v-model="readyStatusModalIsOpen"
    persistent
    width="900">
    <v-form
      v-model="isFormValid"
      @submit.prevent="save">
      <v-card>
        <v-card-title
          class="headline grey lighten-2">
          Auftrag bereitstellen
        </v-card-title>
        <v-card-text>
          <p>Hier können Sie die E-Mail an den Kunden anpassen:</p>
          <v-textarea
            label="Nachricht"
            v-model="customerInfo"
            :rules="[rules.required]"
          ></v-textarea>
          <p >
            Mit freundlichen Grüßen<br>
            Ihr {{ order.company.name }} Team
          </p>
          <p>
            Der Kunde erhält diesen Text per E-Mail, damit er weiß, dass er sein Hilfsmittel abholen kann, bzw wann es geliefert wird.<br>
            Die E-Mail wird an <span class="text-primary">{{ order.b_email }} </span> versendet.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="isButtonDisabled"
            :loading="loading"
            type="submit">
            Auftrag bereitstellen & Email senden
          </v-btn>
          <v-btn
            @click="closeModal">
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import OrdersAPI from '@/api/orders'
import EmailTemplateAPI from '@/api/emailTemplates'

export default {
  data() {
    return {
      isFormValid: false,
      loading: false,
      rules: {
        required: (value) => !!value || 'Erforderlich',
      },
      customerInfo: '',
    }
  },
  computed: {
    ...mapGetters({
      order: 'order/order',
      readyStatusModalIsOpen: 'order/readyStatusModalIsOpen',
    }),
    isButtonDisabled() {
      return this.loading || !this.isFormValid
    },
  },
  watch: {
    order: {
      handler() {
        this.fetchEmailTemplate()
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapActions('order', ['fetchOrder']),
    save() {
      OrdersAPI.setStatusReady(this.order.id, { customerInfo: this.customerInfo }).then(() => {
        this.$toasted.success('Der Auftragsstatus wurde erfolgreich geändert und die E-Mail versendet.')
        this.closeModal()
        this.fetchOrder(this.$route.params.id)
      }).catch((response) => {
        response
          .json()
          .then((result) => {
            if (result[0] !== undefined && result[0].code !== undefined) {
              this.$toasted.error(result[0].code)
            } else {
              this.$toasted.error('Hier stimmt etwas nicht. Bitte versuchen Sie es später erneut.')
            }
          })
      }).finally(() => {
        this.loading = false
      })
    },
    closeModal() {
      this.$store.commit('order/setReadyStatusModalIsOpen', false)
    },
    fetchEmailTemplate() {
      EmailTemplateAPI.getStatusReadyTemplate(this.order.id).then((response) => {
        this.customerInfo = response
      })
    },
  },
}
</script>
