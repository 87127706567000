<template>
  <v-data-table
    :headers="headers"
    :items="orders"
    hide-default-footer>
    <template
      slot="headerCell"
      slot-scope="{header}"
    >
      <span
        class="font-weight-light text-primary"
        v-text="header.text"
      />
    </template>
    <template
      slot="item"
      slot-scope="{ item }"
    >
      <tr
        class="linked-tr"
        @click="openOrder(item)">
        <td>{{ item.id }}</td>
        <td>{{ item.b_firstname }} {{ item.b_lastname }}</td>
        <td>
          <div
            v-for="orderproduct in item.orderproducts"
            :key="orderproduct.id">
            {{ orderproduct.product.name }}
          </div>
        </td>
        <td>{{ item.total | euro }}</td>
        <td>{{ item.created_at | datetime }}</td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    orders: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      itemsPerPage: [50, 100, -1],
      headers: [
        {
          sortable: false,
          text: '#',
          value: 'id',
        },
        {
          sortable: false,
          text: 'Kunde',
          value: 'name',
        },
        {
          sortable: false,
          text: 'Produkte',
          value: 'orderproducts',
        },
        {
          sortable: false,
          text: 'Gesamtbetrag',
          value: 'total',
        },
        {
          sortable: false,
          text: 'Vom',
          value: 'created_at',
        },
      ],
    }
  },
  methods: {
    openOrder(order) {
      this.$router.push(`/orders/${order.id}`)
    },
  },
}
</script>
