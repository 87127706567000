<template>
  <div>
    <h3 class="mt-0 text-primary">Zahlung</h3>
    <v-row>
      <v-col cols="6">
        Gesamtbetrag (inkl. Lieferung)<br>
        <span class="text-h6">{{ order.total | euro }}</span>
      </v-col>
      <v-col
        v-if="order.alreadyPaid"
        cols="6">
        Bereits bezahlt<br>
        <span class="text-h6 green--text">{{ order.alreadyPaid / 100 | euro }}</span>
      </v-col>
    </v-row>
    <v-btn
      v-if="order.alreadyPaid && order.alreadyPaid / 100 !== order.total"
      :loading="loading"
      @click="balancePayment"
      block
      class="mb-4"
      color="primary"
      type="submit">
      Zahlung jetzt ausgleichen
    </v-btn>
    <template v-if="order.payments.length">
      <v-divider
        class="c-contentCard__divider -fullWidth" />
      <h3>Verlauf</h3>
      <v-container>
        <v-row
          v-for="item in order.payments"
          :key="item.id"
          class="d-flex justify-space-between align-center mb-2"
          :class="[!item.paid ? 'red--text' : '']"
        >
          <span>{{ item.updated_at | datetime }}<br>Zahlung erfolgreich</span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >{{ item.amount / 100 | euro }}
              </span>
            </template>
            <span class="white--text">
              {{ item.amount / 100 | euro }} davon {{ item.payment_fee / 100 | euro }} Gebühren</span>
          </v-tooltip>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OrdersAPI from '@/api/orders'

export default {
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      order: 'order/order',
    }),
  },
  methods: {
    ...mapActions('order', ['fetchOrder']),
    balancePayment() {
      this.loading = true
      OrdersAPI.balancePayment(this.order.id).then(() => {
        this.$toasted.success('Die Zahlung wurde erfolgreich ausgeglichen')
        this.fetchOrder(this.$route.params.id)
      }).catch(() => {
        this.$toasted.error('Hier stimmt etwas nicht. Bitte versuchen Sie es später erneut.')
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
