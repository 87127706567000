export default {
  MAP_BOX: {
    ACCESS_TOKEN: 'pk.eyJ1IjoibW9yaXR1ciIsImEiOiJubXl6dEdrIn0.mWJLQ9UKC58rHKWE8ZMkOA',
  },
  PAYMENT_METHODS: [
    {
      VALUE: 1,
      TEXT: 'Bar',
    },
    {
      VALUE: 2,
      TEXT: 'EC-Karte',
    },
    {
      VALUE: 3,
      TEXT: 'Kreditkarte',
    },
  ],
  PAYMENT_TYPES: {
    PAYMENT_CASH: 'cash',
    PAYMENT_STRIPE: 'stripe',
  },
  ORDER_STATUSES: {
    STATUS_NEW: {
      VALUE: 0,
      TEXT: 'Neu',
      STEP: 1,
    },
    STATUS_ACCEPTED: {
      VALUE: 10,
      TEXT: 'Angenommen',
      STEP: 2,
    },
    STATUS_READY: {
      VALUE: 20,
      TEXT: 'Bereitgestellt',
      STEP: 3,
    },
    STATUS_DELIVERED: {
      VALUE: 25,
      TEXT: 'Geliefert',
      STEP: 4,
    },
    STATUS_COMPLETED: {
      VALUE: 30,
      TEXT: 'Abgeschlossen',
      STEP: 5,
    },
  },
  DELIVERY_TYPES: {
    own: {
      VALUE: 'own',
      TEXT: 'Lieferung',
      ICON: '',
    },
    dhl: {
      VALUE: 'dhl',
      TEXT: 'DHL',
      ICON: '',
    },
    pickup: {
      VALUE: 'pickup',
      TEXT: 'Abholung',
      ICON: '',
    },
  },
  SUBDELIVERY_TYPES: {
    own_own: {
      VALUE: 'own_own',
      TEXT: 'Lieferung und Zurückholen',
      ICON: 'swap_horiz',
    },
    own_pickup: {
      VALUE: 'own_pickup',
      TEXT: 'Nur Lieferung',
      ICON: 'arrow_right',
    },
    pickup_own: {
      VALUE: 'pickup_own',
      TEXT: 'Nur Zurückholen',
      ICON: 'arrow_left',
    },
  },
  PRODUCTS: {
    WHEELCHAIR: {
      ID: 1,
    },
    LIGHT_WHEELCHAIR: {
      ID: 2,
    },
    POWER_WHEELCHAIR: {
      ID: 7,
    },
    SCOOTER: {
      ID: 12,
    },
  },
  NOTES: {
    TYPE_ORDER: 0,
  },
}
