import base from '@/api/base'

export default {
  getConsultingAppointmentProvisionReport(from, until, partners) {
    return base.post('/me/backend/reports/consulting-appointment-provision', {
      from,
      until,
      partners,
    })
  },
  getOrderProvisionReport(from, until, partners) {
    return base.post('/me/backend/reports/order-provision', {
      from,
      until,
      partners,
    })
  },
  exportOrderProvisionReport(from, until, partners) {
    return base.getFileWithParams('/me/backend/reports/order-provision/export', {
      from,
      until,
      partners,
    })
  },
}
