import base from '@/api/base'

export default {
  getCompanyByAuthUser() {
    return base.get('/me/backend/companies/user')
  },
  getCompanies() {
    return base.get('/me/backend/companies')
  },
  getCompany(id) {
    return base.get(`/me/backend/companies/${id}`)
  },
  create(model, user) {
    return base.post('/me/backend/companies', {
      company: model,
      user,
    })
  },
  update(model) {
    return base.post(`/me/backend/companies/${model.id}`, model)
  },
  updateContactData(model) {
    return base.post(`/me/backend/companies/${model.id}/setContactData`, {
      contact_name: model.contact_name,
      contact_mail: model.contact_mail,
      contact_tel: model.contact_tel,
    })
  },
  updateSuppliableZips(model) {
    return base.post(`/me/backend/companies/${model.id}/setSuppliableZips`, {
      suppliable_zips: model.suppliable_zips,
    })
  },
  addHoliday(model, date) {
    return base.post(`/me/backend/companies/${model.id}/holiday`, {
      date,
    })
  },
  removeHoliday(model, id) {
    return base.post(`/me/backend/companies/${model.id}/holiday/${id}/remove`)
  },
  updateLogoAndSlogan(companyId, data) {
    return base.sendFile(`/me/backend/companies/${companyId}/setLogoAndSlogan`, data)
  },
  deactivate(company) {
    return base.post(`/me/backend/companies/${company.id}/deactivate`)
  },
  activate(company) {
    return base.post(`/me/backend/companies/${company.id}/activate`)
  },
  getAllSuppliableZips() {
    return base.get('/me/backend/companies/allSuppliableZips')
  },
}
