<template>
  <div>
    <div class="d-flex justify-space-between align-start">
      <h3 class="mt-0 text-primary">Interne Notizen</h3>
      <AddNoteModal
        :type="$constants.NOTES.TYPE_ORDER"
        :foreign-id="Number($route.params.id)">
        <v-btn>
          + Interne Notiz
        </v-btn>
      </AddNoteModal>
    </div>
    <v-divider
      class="c-contentCard__divider -fullWidth -height-2 mt-4" />
    <div
      v-for="note in notes"
      :key="`note-${note.id}`"
    >
      <Note
        class="mt-4"
        :note="note" />
      <v-divider
        v-if="note !== notes.slice(-1)[0]"
        class="c-contentCard__divider -fullWidth -height-2 mt-4" />
    </div>
    <div
      v-if="!notes.length"
      class="mt-4">
      Es gibt noch keine internen Notizen zu dieser Mietanfrage.
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Note from '@/components/note/Note.vue'
import AddNoteModal from '@/components/note/AddNoteModal.vue'

export default {
  created() {
    this.fetchNotes({ type: this.$constants.NOTES.TYPE_ORDER, id: this.$route.params.id })
  },
  computed: {
    ...mapGetters({
      notes: 'note/notes',
    }),
  },
  methods: {
    ...mapActions('note', ['fetchNotes']),
  },
  components: {
    Note,
    AddNoteModal,
  },
}
</script>
