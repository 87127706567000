<template>
  <div>
    <p>Hier können Sie die standard (brutto) Lieferkosten für diese Filiale hinterlegen.</p>
    <v-alert
      border="top"
      colored-border
      type="info"
      elevation="2"
    >
      Die Lieferkosten beziehen sich auf den einfachen Weg.<br>
      <br>
      Die eingetragenen Lieferkosten beinhalten 19 % Ust.<br>
      Der tatsächliche Umsatzsteuersatz der Lieferkosten berechnet sich nach der Hauptleistung. Dies ist immer das Miethilfsmittel.<br>
      <strong>Beispiel:</strong><br>
      <table class="s-exampleTable">
        <thead>
          <tr>
            <th>Produkt</th>
            <th>Brutto</th>
            <th>Netto</th>
            <th>USt.</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Elektromobil 19%</td>
            <td class="-monospace">35,00 €</td>
            <td class="-monospace">29,41 €</td>
            <td class="-monospace">5,59 €</td>
          </tr>
          <tr>
            <td>Rollstuhl 7%</td>
            <td class="-monospace">35,00 €</td>
            <td class="-monospace">32,71 €</td>
            <td class="-monospace">2,29 €</td>
          </tr>
        </tbody>
      </table>
      <div class="mt-2">
        Hat ein Miethilfsmittel einen USt. Satz von 7 %,
        wie z. B. Rollstühle, berechnet das Programm automatisch die Lieferkosten mit 7 % USt. Bei einem Elektromobil z.B. sind es 19 %.<br>
      </div>
    </v-alert>
    <v-text-field
      style="max-width:160px"
      label="Lieferpreis pro km"
      append-icon="euro"
      type="number"
      min="0"
      step="0.01"
      placeholder="1.50"
      persistent-placeholder
      :value="selectedBranchOffice.delivery_price_km"
      @change="updateDeliveryPriceKm($event)"
      hint="Dies ist der Standardpreis der für die Berechnung der Lieferkosten verwendet wird"
    />
    <h4 class="text-primary">Lieferkosten pro Postleitzahl</h4>
    <p>Hier können Sie individuelle (brutto) Lieferkosten pro Postleitzahl hinterlegen. Bei einer Lieferung in die jeweiligen Postleitzahlen gilt dann nicht der Standard Lieferpreis pro km, sondern der feste Preis, der hier hinterlegt ist. Im Feld "Postleitzahlen" können Sie in jeder Zeile beliebig viele Postleitzahlen hinterlegen. </p>
    <v-data-table
      :headers="headers"
      :items="selectedBranchOffice.zips"
      class="elevation-1 mb-4 top deliveryPrices"
      hide-default-footer
      disable-pagination>
      <template
        slot="item"
        slot-scope="props">
        <BranchDeliveryCostsRow
          :index="props.index"
          :item="props.item"
          :count-of-zips="selectedBranchOffice.zips.length"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BranchDeliveryCostsRow from '@/components/company/BranchDeliveryCostsRow.vue'

export default {
  data() {
    return {
      headers: [
        {
          text: 'Postleitzahlen',
          value: 'zips',
        },
        {
          text: 'Lieferpreis',
          value: 'delivery_price',
        },
        {
          text: '',
        },
      ],
    }
  },
  created() {
    if (this.selectedBranchOffice.zips.length === 0) {
      this.addEmptyZipItem()
    }
  },
  computed: {
    ...mapGetters({
      selectedBranchOffice: 'branchOffice/selectedBranchOffice',
    }),
  },
  watch: {
    'selectedBranchOffice.zips': {
      handler(after) {
        const lastItem = after.slice(-1)[0]
        if (lastItem === undefined) {
          return
        }
        if (lastItem.zip !== '' || lastItem.delivery_price !== '') {
          this.addEmptyZipItem()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    updateDeliveryPriceKm(e) {
      this.$store.commit('branchOffice/setDeliveryPriceKmForSelectedBranchOffice', e)
    },
    addEmptyZipItem() {
      this.$store.commit('branchOffice/addEmptyZipRowInSelectedBranchOffice')
    },
  },
  components: {
    BranchDeliveryCostsRow,
  },
}
</script>

<style lang="scss" scoped>
.s-exampleTable {
  border-spacing: 0;
  border-collapse: collapse;

  td, th {
    padding: 5px;
    border: 1px solid #adadad;
    overflow: hidden;
  }
  th {
    text-align: left;
    font-weight: bold;
  }
  td.-monospace {
    font-family: monospace;
  }
}
</style>
