<template>
  <v-form @submit.prevent="save">
    <v-card>
      <v-card-text class="pt-0 pb-8">
        <v-row class="pt-0">
          <v-col
            cols="12"
            class="pt-0">
            <h3>MeinHilfsmittel.de Liefergebiete</h3>
            <div>
              Wählen Sie in der Karte aus, welche Postleitzahlengebiete Sie beliefern.<br>
              Das Liefergebiet ist aktuell nur für MeinHilfsmittel.de relevant.
            </div>
            <Mapbox
              v-if="companyData && centerOfMap"
              v-model="companyData.suppliable_zips"
              :center="centerOfMap"
              class="my-3"/>
            <Mapbox
              v-else-if="companyData"
              v-model="companyData.suppliable_zips"
              class="my-3"/>
            <div class="caption">
              Nutzen Sie
              <v-chip
                label
                x-small>
                strg
              </v-chip>
              + Mausrad um zu zoomen
            </div>
            <v-textarea
              label="Postleitzahlen*"
              placeholder="Hier bitte Ihre Postleitzahlen eingeben"
              v-model="companyData.suppliable_zips"/>
          </v-col>
          <RequiredFieldsLabel/>
        </v-row>
        <v-card class="s-bottomActionCard">
          <v-card-text>
            <v-layout>
              <v-flex shrink>
                <v-btn
                  :disabled="loading"
                  :loading="loading"
                  color="success"
                  type="submit">
                  Speichern
                </v-btn>
              </v-flex>
              <v-spacer/>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import CompanyAPI from '@/api/companies'
import RequiredFieldsLabel from '@/components/company/RequiredFieldsLabel.vue'
import dirtyState from '@/mixins/dirtyState'
import { mapGetters } from 'vuex'
import Mapbox from '../../Mapbox.vue'

export default {
  mixins: [dirtyState],
  data() {
    return {
      loading: false,
      companyData: null,
    }
  },
  computed: {
    ...mapGetters({
      company: 'company/company',
    }),
    centerOfMap() {
      if (!this.companyData || !this.companyData.branch_offices.length) {
        return null
      }
      return [this.companyData.branch_offices[0].lng, this.companyData.branch_offices[0].lat]
    },
  },
  watch: {
    company: {
      handler() {
        this.companyData = { ...this.company }
        this.dirtyStateWatch('companyData')
      },
      deep: true,
      immediate: true,
    },
    'companyData.suppliable_zips': {
      handler() {
        this.companyData.suppliable_zips = this.companyData.suppliable_zips.replace(', ', ',')
      },
      immediate: true,
    },
  },
  methods: {
    save() {
      if (!this.companyData.suppliable_zips) {
        this.$toasted.error('Bitte füllen Sie die belieferbaren Gebiete als Postleitzahl aus')
        return
      }

      this.loading = true
      CompanyAPI.updateSuppliableZips(this.companyData).then(() => {
        this.$toasted.success('Die Liefergebiete wurden erfolgreich gespeichert')
        this.loading = false
        this.dirtyStateUnset()
      })
    },
  },
  components: {
    RequiredFieldsLabel,
    Mapbox,
  },
}
</script>
