<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="500">
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="mode == 'update'"
        class="ml-2"
        x-small
        v-on="on">
        <slot></slot>
      </v-btn>
      <v-btn
        v-else
        v-on="on">
        <slot></slot>
      </v-btn>
    </template>

    <v-form
      v-model="isFormValid"
      @submit.prevent="save">
      <v-card>
        <v-card-title
          class="headline grey lighten-2">
          Produkt bearbeiten
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              v-if="mode === 'new'">
              <v-select
                v-model="editedOrderProduct.product_id"
                :rules="[rules.required]"
                :items="rentProducts"
                item-text="name"
                item-value="id"
                label="Produkt"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="menuStart"
                :close-on-content-click="false"
                min-width="290px"
                offset-y
                transition="scale-transition">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="startDateFormatted"
                    :rules="[rules.required]"
                    v-on="on"
                    label="Start"
                    prepend-icon="event"
                    readonly
                    @blur="editedOrderProduct.start = parseDate(startDateFormatted)"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="editedOrderProduct.start"
                  header-color="primary"
                  @input="menuStart = false"
                />
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="menuEnd"
                :close-on-content-click="false"
                min-width="290px"
                offset-y
                transition="scale-transition">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="endDateFormatted"
                    :rules="[rules.required]"
                    v-on="on"
                    label="Ende"
                    prepend-icon="event"
                    readonly
                    @blur="editedOrderProduct.end = parseDate(endDateFormatted)"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="editedOrderProduct.end"
                  header-color="primary"
                  @input="menuEnd = false"
                />
              </v-menu>
            </v-col>
            <v-col
              v-if="additionalInformationExists"
              cols="12">
              <h3 class="my-0">Zusatzinformationen</h3>
            </v-col>
            <v-col
              v-if="additionalInformationExists"
              cols="12">
              <v-text-field
                v-model="editedOrderProduct.seatWidth"
                label="Sitzbreite"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="additionalInformationExists"
              cols="12">
              <p>Beinstütze Hochschwenkbar</p>
              <v-radio-group v-model="editedOrderProduct.legSupport">
                <v-radio
                  v-for="item in this.legSupportItems"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :disabled="isButtonDisabled"
            :loading="loading"
            type="submit">
            Speichern
          </v-btn>
          <v-btn
            @click="dialog = false">
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OrdersAPI from '@/api/orders'
import ProductsAPI from '@/api/products'

export default {
  props: {
    orderProduct: {
      type: Object,
      default: null,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isFormValid: false,
      loading: false,
      dialog: false,
      rules: {
        required: (value) => !!value || 'Erforderlich',
      },
      menuStart: null,
      menuEnd: null,
      editedOrderProduct: {
        start: '',
        end: '',
      },
      legSupportItems: [
        {
          label: 'Nein - Nur Standard Beinstütze',
          value: 'keine',
        },
        {
          label: 'Links Hochschwenkbar',
          value: 'links',
        },
        {
          label: 'Rechts Hochschwenkbar',
          value: 'rechts',
        },
        {
          label: 'Beidseitig Hochschwenkbar',
          value: 'beidseitig',
        },
      ],
      rentProducts: [],
    }
  },
  created() {
    if (this.mode === 'new') {
      this.fetchRentProducts()
    } else {
      this.editedOrderProduct = this.getOrderProductData()
    }
  },
  computed: {
    ...mapGetters({
      order: 'order/order',
    }),
    startDateFormatted() {
      return this.formatDate(this.editedOrderProduct.start)
    },
    endDateFormatted() {
      return this.formatDate(this.editedOrderProduct.end)
    },
    isButtonDisabled() {
      return this.loading || !this.isFormValid
    },
    additionalInformationExists() {
      return [
        this.$constants.PRODUCTS.WHEELCHAIR.ID,
        this.$constants.PRODUCTS.LIGHT_WHEELCHAIR.ID,
        this.$constants.PRODUCTS.POWER_WHEELCHAIR.ID,
        this.$constants.PRODUCTS.SCOOTER.ID].includes(this.editedOrderProduct.product_id)
    },
  },
  methods: {
    ...mapActions('order', ['fetchOrder']),
    save() {
      const apiCall = this.mode === 'new'
        ? OrdersAPI.storeOrderProduct(this.order.id, this.editedOrderProduct)
        : OrdersAPI.updateOrderProduct(this.order.id, this.editedOrderProduct.id, this.editedOrderProduct)
      apiCall.then(() => {
        this.dialog = false
        this.fetchOrder(this.$route.params.id)
      }).catch(() => {
        this.$toasted.error('Hier stimmt etwas nicht. Bitte versuchen Sie es später erneut.')
      }).finally(() => {
        this.loading = false
      })
    },
    fetchRentProducts() {
      ProductsAPI.getRentProducts().then((response) => {
        this.rentProducts = response
      })
    },
    getOrderProductData() {
      if (this.orderProduct === null) {
        return {}
      }
      return {
        id: this.orderProduct.id,
        start: this.orderProduct.start,
        end: this.orderProduct.end,
        product_id: this.orderProduct.product_id,
        seatWidth: this.orderProduct.additional_information !== null ? this.orderProduct.additional_information.seatWidth : null,
        legSupport: this.orderProduct.additional_information !== null ? this.orderProduct.additional_information.legSupport : null,
      }
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  },
}
</script>
