<template>
  <v-tooltip
    bottom
    :disabled="!isDisabled">
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
        style="height: 100%;">
        <v-btn
          color="primary"
          :disabled="isDisabled"
          :loading="isLoading"
          class="c-duplicationButton"
          @click="duplicateOrder">
          Neu vergeben
        </v-btn>
      </div>
    </template>
    <span class="white--text">
      {{ disablingReason }}
    </span>
  </v-tooltip>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

import OrdersAPI from '@/api/orders'

export default {
  data() {
    return {
      duplicationUrl: null,
      error: null,
      isLoading: false,
    }
  },
  mounted() {
    if (moment(this.order.orderproducts[0].end).isBefore()) {
      return
    }
    this.isLoading = true
    OrdersAPI.getDuplicationUrl(this.order.id).then((response) => {
      this.isLoading = false
      if (response.error) {
        this.error = response.error
        return
      }
      let domain = 'https://meinhilfsmittel.de'
      if (window.location.hostname === 'backend.staging.meinhilfsmittel.de') {
        domain = 'https://staging.meinhilfsmittel.de'
      }
      if (window.location.hostname === 'localhost') {
        domain = 'http://localhost:8081'
      }
      this.duplicationUrl = `${domain}/warenkorb/alternativen/${response.order_id}#${response.signature}`
    })
  },
  computed: {
    ...mapGetters({
      order: 'order/order',
    }),
    disablingReason() {
      if (this.isLoading) {
        return 'Alternative Anbieter werden noch geprüft...'
      }
      if (this.error) {
        return this.error
      }
      if (moment(this.order.orderproducts[0].end).isBefore()) {
        return 'Diese Bestellung ist bereits abgelaufen.'
      }
      if (!this.duplicationUrl) {
        return 'Bestelldaten konnten nicht abgerufen werden.'
      }
      return null
    },
    isDisabled() {
      return !!this.disablingReason
    },
  },
  methods: {
    duplicateOrder() {
      if (this.isLoading || this.isDisabled || !this.duplicationUrl) {
        return
      }
      window.open(this.duplicationUrl, '_blank')
    },
  },
}
</script>

<style>
.c-duplicationButton {
    height: calc(100% - 8px) !important;
}
</style>
