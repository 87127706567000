import Vue from 'vue'
import VueToasted from 'vue-toasted'
import constantsMixin from '@/mixins/constants'
import base from '@/api/base'
import { sync } from 'vuex-router-sync'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

// Components
import './components'

// Plugins
import './plugins'

// Sync router with store

base.setStore(store)

// Sync store with router
sync(store, router)

Vue.config.productionTip = false

Vue.use(VueToasted, {
  duration: 3000,
})

Vue.mixin(constantsMixin)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
