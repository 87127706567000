<template>
  <v-form
    @submit.prevent="save">
    <v-card>
      <v-card-text class="pt-0 pb-8">
        <v-row>
          <v-col
            cols="12"
            class="pt-0">
            <h3>Allgemein</h3>
          </v-col>
          <v-col
            cols="6"
            class="pt-0">
            <h4 class="text-primary">Unternehmensinformationen</h4>
            <v-text-field
              label="Firmenname*"
              v-model="companyData.name"
            />
            <v-text-field
              label="Geschäftsform*"
              v-model="companyData.legal_form"
            />
            <v-text-field
              label="UStID-Nr"
              v-model="companyData.ust_id"
            />
            <v-text-field
              label="Steuernummer"
              v-model="companyData.tax_id"
            />
            <v-text-field
              label="Registergericht"
              v-model="companyData.registration_court"
            />
            <v-text-field
              label="Geschäftsführer"
              v-model="companyData.ceo"
            />
            <v-text-field
              label="Aufsichtsrat"
              v-model="companyData.supervisory_board"
            />
          </v-col>
          <v-col
            class="pt-0"
            cols="6">
            <h4 class="text-primary">Anschrift</h4>
            <v-text-field
              label="Straße*"
              v-model="companyData.street"
            />
            <v-text-field
              label="Postleitzahl*"
              v-model="companyData.zip"
            />
            <v-text-field
              label="Ort*"
              v-model="companyData.city"
            />
            <h4>Kontaktinformationen</h4>
            <v-text-field
              label="Telefon*"
              v-model="companyData.tel"
            />
            <v-text-field
              hint="Mehrere E-Mail Adressen können mit einem Semikolon ; getrennt angegeben werden."
              label="E-Mail (für Auftragseingänge)*"
              v-model="companyData.mail"/>
            <v-text-field
              label="Webseite*"
              v-model="companyData.website"
            />
            <v-textarea
              label="Anfahrtinfos"
              v-model="companyData.additional_info"
            />
          </v-col>
          <RequiredFieldsLabel/>
          <v-col
            v-if="superadmin"
            cols="4">
            <h4 class="text-primary">Superadmin Einstellungen</h4>
            <v-switch
              label="Zugang zu MeinHilfsmittel"
              v-model="companyData.partner_mh"
            />
            <v-text-field
              v-if="companyData.partner_mh"
              label="Partner-Link"
              prefix="https://meinhilfsmittel.de/"
              type="text"
              v-model="companyData.partner_name"
            />
            <v-switch
              label="Zugang zu MeinElektromobil"
              v-model="companyData.partner_me"
            />
            <v-text-field
              label="Russka Kundennummer"
              type="number"
              v-model="companyData.russka_customernr"
            />
          </v-col>
        </v-row>
        <v-row v-if="!companyData.id">
          <v-col
            cols="4">
            <v-switch
              label="Administratorkonto erstellen"
              v-model="createAdmin"
            />
          </v-col>
          <v-col cols="8">
            <template v-if="createAdmin">
              <h3>Administrator Account anlegen</h3>
              <v-text-field
                label="E-Mail*"
                type="email"
                v-model="user.email"
              />
              <v-text-field
                label="Vorname*"
                v-model="user.firstname"
              />
              <v-text-field
                label="Nachname*"
                v-model="user.lastname"
              />
              <v-select
                :items="genders"
                item-text="label"
                item-value="id"
                label="Geschlecht*"
                persistent-hint
                single-line
                v-model="user.foa"
              />
            </template>
          </v-col>
        </v-row>
        <v-card class="s-bottomActionCard">
          <v-card-text>
            <v-layout>
              <v-flex shrink>
                <v-btn
                  color="success"
                  :loading="loading"
                  type="submit">
                  Speichern
                </v-btn>
              </v-flex>
              <v-spacer/>
              <v-flex shrink>
                <ConfirmDeactivationModal
                  v-if="superadmin && !company.deactivated_at"
                  :company="company"
                  @success="changedActivation"
                />
                <ConfirmActivationModal
                  v-else-if="superadmin && company.deactivated_at"
                  :company="company"
                  @success="changedActivation"/>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import CompanyAPI from '@/api/companies'
import RequiredFieldsLabel from '@/components/company/RequiredFieldsLabel.vue'
import dirtyState from '@/mixins/dirtyState'
import ConfirmDeactivationModal from '../ConfirmDeactivationModal.vue'
import ConfirmActivationModal from '../ConfirmActivationModal.vue'

export default {
  mixins: [dirtyState],
  props: {
    company: {
      type: Object,
      required: true,
    },
    superadmin: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      createAdmin: false,
      genders: [
        { id: 1, label: 'männlich' },
        { id: 0, label: 'weiblich' },
      ],
      user: {},
      companyData: {},
    }
  },
  watch: {
    company: {
      handler() {
        Object.keys(this.company).forEach((key) => {
          this.$set(this.companyData, key, this.company[key])
        })
        this.dirtyStateWatch('companyData')
      },
      immediate: true,
    },
  },
  methods: {
    save() {
      if (!this.companyData.name
          || !this.companyData.legal_form
          || !this.companyData.street
          || !this.companyData.zip
          || !this.companyData.city
          || !this.companyData.tel
          || !this.companyData.mail
          || !this.companyData.website
      ) {
        this.$toasted.error('Bitte füllen Sie bitte alle Felder sorgfältig aus')
        return
      }

      if (this.createAdmin && !this.companyData.id) {
        if (!this.user.firstname
          || !this.user.lastname
          || !this.user.email
          || typeof this.user.foa === 'undefined') {
          this.$toasted.error('Bitte geben Sie alle Informationen zum Administratorkonto an.')
          return
        }
      }

      this.loading = true
      const apiCall = this.companyData.id
        ? CompanyAPI.update(this.companyData)
        : CompanyAPI.create(this.companyData, this.user)

      apiCall.then((response) => {
        this.$emit('update', response.id)
        if (this.companyData.id) {
          this.$toasted.success('Die Partnerinformationen wurden erfolgreich gespeichert')
        } else {
          this.$toasted.success('Der Partner wurde erfolgreich angelegt')
          this.user = {}
          this.createAdmin = false
          this.$router.push({
            name: 'company',
            params: {
              id: response.id,
            },
          })
        }
        this.loading = false
        this.dirtyStateUnset()
      })
    },
    changedActivation() {
      this.$emit('update', this.companyData.id)
    },
  },
  components: {
    RequiredFieldsLabel,
    ConfirmDeactivationModal,
    ConfirmActivationModal,
  },
}
</script>
