<template>
  <v-tabs slider-color="primary">
    <v-tab
      :disabled="tab !== 'Allgemein' && !company.id"
      :key="tab"
      ripple
      v-for="tab in tabs">
      {{ tab }}
    </v-tab>
    <v-tab-item
      :key="tab"
      v-for="tab in tabs">
      <template v-if="tab === 'Allgemein'">
        <GeneralCompanyTab
          :company="company"
          :superadmin="superadmin"
          @update="$emit('update', $event)"
        />
      </template>
      <template v-if="tab === 'Ansprechpartner'">
        <ContactPersonTab
          :company="company"
        />
      </template>
      <template v-if="tab === 'Miethilfsmittel'">
        <RentProductsTab
          :company="company"
        />
      </template>
      <template v-if="tab === 'Liefergebiete'">
        <ShippingAreasTab
          :company="company"
        />
      </template>
      <template v-if="tab === 'Feiertage'">
        <PublicHolidaysTab
          :company="company"
          :holidays="holidays"
          @update="$emit('update')"
        />
      </template>
      <template v-if="tab === 'Filialen'">
        <BranchTab
          :company="company"
        />
      </template>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import BranchTab from '@/components/company/tabs/BranchOfficesTab.vue'
import GeneralCompanyTab from '@/components/company/tabs/GeneralCompanyTab.vue'
import ContactPersonTab from '@/components/company/tabs/ContactPersonTab.vue'
import ShippingAreasTab from '@/components/company/tabs/ShippingAreasTab.vue'
import PublicHolidaysTab from '@/components/company/tabs/PublicHolidaysTab.vue'
import RentProductsTab from '@/components/company/tabs/RentProductsTab.vue'

export default {
  props: {
    company: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
    holidays: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
    superadmin: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    tabs() {
      const tabs = {
        general: 'Allgemein',
        contactPerson: 'Ansprechpartner',
        rentProducts: 'Miethilfsmittel',
        deliveryArea: 'Liefergebiete',
        holiday: 'Feiertage',
        branchOffices: 'Filialen',
      }
      if (!this.company.partner_mh) {
        delete tabs.deliveryArea
        delete tabs.rentProducts
      }
      return tabs
    },
  },
  components: {
    PublicHolidaysTab,
    GeneralCompanyTab,
    ShippingAreasTab,
    ContactPersonTab,
    BranchTab,
    RentProductsTab,
  },
}
</script>
