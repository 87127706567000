<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      width="500">
      <template v-slot:activator="{ on }">
        <v-btn
          color="error"
          :loading="loading"
          v-on="on">
          Löschen
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">Bewertung löschen</v-card-title>
        <v-card-text>
          Möchten Sie diese Bewertung wirklich löschen?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            :loading="loading"
            color="error"
            type="button"
            @click="confirm">
            Löschen
          </v-btn>
          <v-btn
            @click="dialog = false">
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RatingsAPI from '@/api/ratings'

export default {
  props: {
    rating: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      dialog: false,
    }
  },
  methods: {
    confirm() {
      this.loading = true

      RatingsAPI.delete(this.rating).then(() => {
        this.$toasted.success('Die Bewertung wurde gelöscht')
        this.$router.push('/ratings')
      }).catch(() => {
        this.$toasted.error('Die Bewertung konnte leider nicht gelöscht werden')
      }).finally(() => {
        this.loading = false
        this.dialog = false
      })
    },
  },
}
</script>
