<template>
  <table>
    <thead>
      <th>Wochentag</th>
      <th>von</th>
      <th>bis</th>
      <th></th>
      <template v-if="anyDayHasSecondOpeningTime">
        <th>von</th>
        <th>bis</th>
      </template>
      <template v-else>
        <th></th>
        <th></th>
      </template>
    </thead>
    <tbody>
      <tr
        v-for="(day, index) in weekdays"
        :key="index">
        <td class="first-column column">{{ day.title }}</td>
        <td class="column">
          <VueTimepicker
            :minute-interval="5"
            :value="getValue(index, 0, 'begin')"
            @change="setValue(index, 0, 'begin', $event)"
          />
        </td>
        <td class="column">
          <VueTimepicker
            :minute-interval="5"
            :value="getValue(index, 0, 'end')"
            @change="setValue(index, 0, 'end', $event)"
          />
        </td>
        <td class="column">
          <v-checkbox
            :input-value="day.openingTimes.length > 1"
            label="Weitere Öffnungszeit"
            @change="toggleSecondOpeningTime(index)"
          />
        </td>
        <template v-if="day.openingTimes.length > 1">
          <td class="column">
            <VueTimepicker
              :minute-interval="5"
              :value="getValue(index, 1, 'begin')"
              @change="setValue(index, 1, 'begin', $event)"
            />
          </td>
          <td class="column">
            <VueTimepicker
              :minute-interval="5"
              :value="getValue(index, 1, 'end')"
              @change="setValue(index, 1, 'end', $event)"
            />
          </td>
        </template>
      </tr>
    </tbody>
  </table>
</template>

<script>
import VueTimepicker from 'vue2-timepicker'

export default {
  props: {
    branch: {
      type: Object,
      default() {
        return {}
      },
      required: false,
    },
  },
  data() {
    return {
      weekdays: [],
    }
  },
  created() {
    const temporaryWeekdays = [
      { title: 'Montag', openingTimes: [{}] },
      { title: 'Dienstag', openingTimes: [{}] },
      { title: 'Mittwoch', openingTimes: [{}] },
      { title: 'Donnerstag', openingTimes: [{}] },
      { title: 'Freitag', openingTimes: [{}] },
      { title: 'Samstag', openingTimes: [{}] },
      { title: 'Sonntag', openingTimes: [{}] },
    ]

    if (this.branch.opening_times) {
      this.branch.opening_times.forEach((item) => {
        temporaryWeekdays[item.day_of_week].openingTimes[item.daytime] = {
          begin: item.from,
          end: item.until,
        }
      })
    }
    this.weekdays = temporaryWeekdays
  },
  computed: {
    anyDayHasSecondOpeningTime() {
      return this.weekdays.filter((item) => item.openingTimes.length > 1).length > 0
    },
  },
  methods: {
    getValue(day_of_week, daytime, timeType) {
      if (!this.weekdays) {
        return null
      }

      if (!this.weekdays[day_of_week].openingTimes[daytime]) {
        return null
      }

      const date = this.weekdays[day_of_week].openingTimes[daytime][timeType]
      if (!date) {
        return null
      }
      return {
        HH: date.toString().substr(0, 2),
        mm: date.toString().substr(3, 2),
      }
    },
    setValue(day_of_week, daytime, timeType, $event) {
      if (!this.weekdays[day_of_week].openingTimes[daytime]) {
        this.weekdays[day_of_week].openingTimes[daytime] = {}
      }
      if ($event && $event.data.HH && $event.data.mm) {
        this.weekdays[day_of_week].openingTimes[daytime][timeType] = `${$event.data.HH}:${$event.data.mm}`
        this.$emit('currentWeekdays', this.weekdays)
      }
    },
    toggleSecondOpeningTime(day_of_week) {
      if (this.weekdays[day_of_week].openingTimes
                && this.weekdays[day_of_week].openingTimes.length > 1) {
        this.weekdays[day_of_week].openingTimes.splice(1, 1)
      } else {
        this.weekdays[day_of_week].openingTimes.push({})
      }
    },
  },
  components: {
    VueTimepicker,
  },
}
</script>

<style lang="scss" scoped>
table {
    width: 100%;

    th, td {
        text-align: left;
        width: 16.666%;
    }

    .first-column {
        font-weight: bold;
    }
}
</style>
