<template>
  <v-card>
    <v-card-text class="pt-0">
      <BranchOffices @selectBranch="selectBranch" />
    </v-card-text>
  </v-card>
</template>

<script>
import BranchOffices from '@/components/company/BranchOffices.vue'
import { mapActions } from 'vuex'

export default {
  props: {
    company: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
  },
  watch: {
    $route: {
      handler() {
        this.fetchBranchOffices(this.company.id)
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('branchOffice', ['fetchBranchOffices']),
    refreshBranchOffices() {
      this.fetchBranchOffices(this.company.id)
    },
    selectBranch(branch) {
      this.$router.push({
        name: 'company.branchOffice',
        params: {
          id: this.company.id,
          branchOfficeId: branch ? branch.id : 'new',
        },
      })
    },
  },
  components: {
    BranchOffices,
  },
}
</script>
