export default {
  rentProducts: (state) => state.rentProducts,
  loading: (state) => state.loading,
  productName: (state) => (productId) => {
    const product = state.rentProducts.find((product) => productId === product.id)

    if (product) {
      return product.name
    }

    return ''
  },
}
