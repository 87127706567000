<template>
  <v-dialog
    v-model="dialog"
    width="500">
    <template v-slot:activator="{ on }">
      <v-icon
        v-on="on"
        color="red">
        delete_forever
      </v-icon>
    </template>

    <v-card>
      <v-card-title class="headline grey lighten-2">
        Bestätigung erforderlich
      </v-card-title>

      <v-card-text>
        {{ message }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          @click="confirm">
          Ja, löschen
        </v-btn>
        <v-btn
          color="error"
          @click="dialog = false">
          Abbrechen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    confirm() {
      this.$emit('success')
      this.dialog = false
    },
  },
}
</script>
