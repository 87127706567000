import Vue from 'vue'
import moment from 'moment'
import idHelper from '@/utils/idHelper'

Vue.filter('daytime', (value) => {
  switch (value) {
    case 'morning':
      return 'Morgens'
    case 'instant':
      return 'Sofort'
    case 'afternoon':
      return 'Nachmittags'
  }
  return ''
})

Vue.filter('recallStatus', (value) => {
  switch (value) {
    case 0:
      return 'Neu'
    case 10:
      return 'Erneut versuchen'
    case 20:
      return 'Unerreichbar'
    case 30:
      return 'Erfolgreich'
  }
  return ''
})

Vue.filter('datetime', (value) => {
  if (value) {
    return `${moment(value).format('DD.MM.YYYY HH:mm')} Uhr`
  }
  return ''
})

Vue.filter('dayMonth', (value) => {
  if (value) {
    return moment(value).format('DD.MM')
  }
  return ''
})

Vue.filter('date', (value) => {
  if (value) {
    return moment(value).format('DD.MM.YYYY')
  }
  return ''
})

Vue.filter('time', (value) => {
  if (value) {
    return `${moment(value).format('HH:mm')} Uhr`
  }
  return ''
})

Vue.filter('fullId', (item, prefix) => idHelper.getFullId(item, prefix))

Vue.filter('consultingAppointmentStatus', (value) => {
  switch (value) {
    case 1:
      return 'Neu'
    case 10:
      return 'Akzeptiert'
    case 20:
      return 'Abgeschlossen'
  }
  return ''
})

Vue.filter('euro', (value) => {
  if (typeof value === 'string') {
    value = parseFloat(value)
  }
  if (value) {
    return `${value.toFixed(2)}€`
  }
  return '0.00€'
})
Vue.filter('limitString', (str, limit = 200) => {
  if (str.length > limit) {
    str = str.substring(0, limit)
  }
  return str
})
