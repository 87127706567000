/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
  {
    path: '/login',
    name: 'login',
    view: 'auth/Login',
    meta: {
      layout: 'plain',
    },
  },
  {
    path: '/logout',
    name: 'logout',
    view: 'auth/Logout',
  },
  {
    path: '/dashboard',
    // Relative to /src/views
    name: 'dashboard',
    view: 'Dashboard',
    meta: {
      name: 'Herzlich Willkommen!',
    },
  },
  {
    path: '/product-management',
    name: 'products',
    view: 'products/Products',
    meta: {
      name: 'Produkte',
    },
  },
  {
    path: '/product-management/:id',
    name: 'edit_product',
    view: 'products/EditProduct',
    meta: {
      name: 'Produkt bearbeiten',
    },
  },
  {
    path: '/appointments/recalls',
    name: 'recall_appointments',
    view: 'appointments/recalls/Recalls',
    meta: {
      name: 'Rückrufwünsche',
    },
  },
  {
    path: '/appointments/recalls/:id',
    name: 'recall_appointment',
    view: 'appointments/recalls/Recall',
    meta: {
      name: 'Rückrufwunsch',
    },
  },
  {
    path: '/appointments/consulting',
    name: 'consulting_appointments',
    view: 'appointments/consulting/ConsultingAppointments',
    meta: {
      name: 'Beratungstermine',
    },
  },
  {
    path: '/appointments/consulting/:id',
    name: 'consulting_appointment',
    view: 'appointments/consulting/ConsultingAppointment',
    meta: {
      name: 'Beratungstermin',
    },
  },
  {
    path: '/company-management',
    name: 'companies',
    view: 'companies/Companies',
    meta: {
      name: 'Partner',
    },
  },
  {
    path: '/company-management/:id',
    view: 'companies/Company',
    meta: {
      name: 'Partner',
    },
    children: [
      {
        name: 'company.default',
        path: '',
        component: () => import('@/components/company/tabs/GeneralCompanyTab.vue'),
      },
      {
        name: 'company.general',
        path: 'general',
        component: () => import('@/components/company/tabs/GeneralCompanyTab.vue'),
      },
      {
        name: 'company.contactPerson',
        path: 'contactPerson',
        component: () => import('@/components/company/tabs/ContactPersonTab.vue'),
      },
      {
        name: 'company.sloganAndLogo',
        path: 'sloganAndLogo',
        component: () => import('@/components/company/tabs/SloganLogoTab.vue'),
      },
      {
        name: 'company.rentProducts',
        path: 'rentProducts',
        component: () => import('@/components/company/tabs/RentProductsTab.vue'),
      },
      {
        name: 'company.deliveryArea',
        path: 'deliveryArea',
        component: () => import('@/components/company/tabs/ShippingAreasTab.vue'),
      },
      {
        name: 'company.holiday',
        path: 'holiday',
        component: () => import('@/components/company/tabs/PublicHolidaysTab.vue'),
      },
      {
        name: 'company.branchOffices',
        path: 'branchOffices',
        component: () => import('@/components/company/tabs/BranchOfficesTab.vue'),
      },
      {
        name: 'company.branchOffice',
        path: 'branchOffices/:branchOfficeId',
        component: () => import('@/components/company/tabs/BranchOfficeTab.vue'),
      },
    ],
  },
  {
    path: '/reports',
    name: 'reports',
    view: 'reports/Report',
    meta: {
      name: 'Auswertungen',
    },
    children: [
      {
        name: 'report.consultingAppointmentReport',
        path: 'consulting-appointment-provision',
        component: () => import('@/components/report/tabs/ConsultingAppointmentReport.vue'),
      },
      {
        name: 'report.orderProvisionReport',
        path: 'order-provision',
        component: () => import('@/components/report/tabs/OrderProvisionReport.vue'),
      },
    ],
  },
  {
    path: '/results',
    name: 'search_results',
    view: 'search/Results',
    meta: {
      name: 'Suchergebnisse',
    },
  },
  {
    path: '/orders',
    name: 'orders',
    view: 'orders/Orders',
    meta: {
      name: 'Mietanfragen',
    },
  },
  {
    path: '/orders/:id',
    name: 'Auftrag',
    view: 'orders/OrderDetails',
  },
  {
    path: '/users',
    name: 'employees',
    view: 'users/Users',
    meta: {
      name: 'Mitarbeiter',
    },
  },
  {
    path: '/users/companies',
    name: 'employees.companies',
    view: 'users/Companies',
    meta: {
      name: 'Mitarbeiter',
    },
  },
  {
    path: '/users/companies/:companyId',
    name: 'employees.users',
    view: 'users/Users',
    meta: {
      name: 'Mitarbeiter',
    },
  },
  {
    path: '/users/companies/:companyId/:userId',
    name: 'employee',
    view: 'users/User',
    meta: {
      name: 'Mitarbeiter bearbeiten',
    },
  },
  {
    path: '/tools',
    view: 'tools/Tools',
    meta: {
      name: 'Tools',
    },
    children: [
      {
        name: 'tools.map',
        path: '',
        meta: {
          name: 'Tools - Liefergebiete',
        },
        component: () => import('@/components/tools/tabs/MapTab.vue'),
      },
    ],
  },
  {
    path: '/ratings',
    view: 'ratings/Ratings',
    meta: {
      name: 'Bewertungen',
    },
  },
  {
    path: '/ratings/new',
    view: 'ratings/RatingForm',
    meta: {
      name: 'Bewertung erstellen',
    },
  },
  {
    path: '/ratings/:ratingId',
    view: 'ratings/RatingForm',
    meta: {
      name: 'Bewertung erstellen',
    },
  },
  {
    path: '/faq',
    view: 'faq/FAQ',
    meta: {
      name: 'Hilfe & FAQ',
    },
  },
  {
    path: '/karte',
    name: 'public-map',
    view: 'public-map/PublicMap',
    meta: {
      name: 'Public Map',
      layout: 'plain',
    },
  },
]
