<template>
  <div>
    <core-toolbar />
    <core-drawer />
    <core-view />
  </div>
</template>

<script>

export default {}
</script>
