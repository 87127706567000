export default {
  getFullId(item, prefix) {
    let id = ''
    if (prefix) {
      id += prefix
    }
    if (item.type === 'Rückruf') {
      id += 'R'
    } else if (item.type === 'Beratungstermin') {
      id += 'B'
    }
    id += item.id
    return id
  },
}
