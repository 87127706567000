<template>
  <div>
    <h3 class="mt-0 text-primary">Dokumente</h3>
    <div v-if="order.status < $constants.ORDER_STATUSES.STATUS_ACCEPTED.VALUE">
      Bitte nehmen Sie den Auftrag an. Anschließend können Rechnung & Mietvertrag herunter geladen werden.
    </div>
    <div v-else>
      <v-btn
        block
        depressed
        x-large
        class="mb-4"
        @click.prevent="fetchLeaseContract">
        <v-icon class="mr-2">history_edu</v-icon>
        Mietvertrag
      </v-btn>
      <v-btn
        block
        depressed
        x-large
        @click.prevent="fetchInvoice">
        <v-icon class="mr-2">receipt_long</v-icon>
        Rechnung
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OrdersAPI from '@/api/orders'
import { downloadPDF } from '@/logic/helpers'

export default {
  computed: {
    ...mapGetters({
      order: 'order/order',
    }),
  },
  methods: {
    fetchInvoice() {
      OrdersAPI.getInvoice(this.order.id).then((response) => {
        downloadPDF(response, `rechnung-${this.order.id}.pdf`)
      })
    },
    fetchLeaseContract() {
      OrdersAPI.getLeaseContract(this.order.id).then((response) => {
        downloadPDF(response, `mietvertrag-${this.order.id}.pdf`)
      })
    },
  },
}
</script>
