<template>
  <v-dialog
    v-model="dialog"
    width="500">
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        color="primary"
        type="button">
        Filiale ändern
      </v-btn>
    </template>

    <v-form @submit.prevent="changeBranchOffice">
      <v-card>
        <v-card-title
          class="headline grey lighten-2">
          Filiale ändern
        </v-card-title>
        <v-card-text>
          <v-select
            item-text="label"
            item-value="id"
            label="Filiale"
            :items="branchOfficeItems"
            v-model="selectedBranchOfficeId" />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            type="button"
            @click.prevent="dialog = false">
            Abbrechen
          </v-btn>
          <v-btn
            color="success"
            type="submit">
            Auswählen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  props: {
    branchOffices: {
      type: Array,
      required: true,
    },
    selected: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      selectedBranchOfficeId: null,
    }
  },
  created() {
    this.selectedBranchOfficeId = this.selected
  },
  computed: {
    branchOfficeItems() {
      return this.branchOffices.map((item) => ({
        id: item.id,
        label: `${item.street}, ${item.zip} ${item.city}`,
      }))
    },
  },
  methods: {
    changeBranchOffice() {
      this.$emit('changeBranchOffice', this.selectedBranchOfficeId)
      this.dialog = false
    },
  },
}
</script>
