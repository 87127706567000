<template>
  <v-col
    cols="12"
    class="pt-0">
    <v-row>
      <h3>
        <v-icon
          medium
          class="mb-1"
          @click="goBack"
        >
          arrow_back_ios
        </v-icon>
        Filiale bearbeiten
      </h3>
      <v-progress-circular
        v-if="branchOfficeLoading"
        indeterminate
        color="primary"
      />
      <v-form
        v-if="!branchOfficeLoading"
        v-model="isFormValid"
        @submit.prevent="save">
        <v-row>
          <v-col cols="6">
            <h4 class="text-primary">Anschrift</h4>
            <v-text-field
              label="Straße*"
              :value="selectedBranchOffice.street"
              @change="updateStreet($event)"
              :rules="[rules.required]"
            />
            <v-text-field
              label="Postleitzahl*"
              :value="selectedBranchOffice.zip"
              @change="updateZip($event)"
              :rules="[rules.required]"
            />
            <v-text-field
              label="Ort*"
              :value="selectedBranchOffice.city"
              @change="updateCity($event)"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="6">
            <BranchMap
              :city="selectedBranchOffice.city"
              :street="selectedBranchOffice.street"
              :zip="selectedBranchOffice.zip"
            />
          </v-col>
          <v-col
            cols="12"
            xl="6">
            <h4 class="text-primary">Öffnungszeiten</h4>
            <BranchOpeningTimes
              :branch="selectedBranchOffice"
              @currentWeekdays="currentWeekdays = $event"
            />
          </v-col>
          <v-col
            cols="12"
            xl="6">
            <h4 class="text-primary mb-0">Zahlungsarten</h4>
            <BranchPaymentMethods />
            <h4 class="text-primary">Lieferkosten</h4>
            <BranchDeliveryCosts />
          </v-col>
        </v-row>
        <RequiredFieldsLabel/>
        <v-col cols="12">
          <v-btn
            :disabled="isButtonDisabled"
            :loading="loading"
            @click.prevent="save"
            color="success">
            Speichern
          </v-btn>
        </v-col>
      </v-form>
    </v-row>
  </v-col>
</template>

<script>
import BranchOfficesAPI from '@/api/branchOffices'
import BranchMap from '@/components/company/BranchMap.vue'
import RequiredFieldsLabel from '@/components/company/RequiredFieldsLabel.vue'
import BranchOpeningTimes from '@/components/company/BranchOpeningTimes.vue'
import BranchDeliveryCosts from '@/components/company/BranchDeliveryCosts.vue'
import BranchPaymentMethods from '@/components/company/BranchPaymentMethods.vue'
import { mapActions } from 'vuex'

export default {
  props: {
    company: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isFormValid: false,
      branchOfficeLoading: true,
      currentWeekdays: null,
      weekdays: [
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag',
        'Sonntag',
      ],
      loading: false,
      rules: {
        required: (value) => !!value || 'Erforderlich',
      },
    }
  },
  computed: {
    selectedBranchOffice: {
      get() {
        return this.$store.getters['branchOffice/selectedBranchOffice']
      },
      set() {
        /* eslint-disable-next-line no-console */
        console.error('trying to update selected branch office which is not supported in this way.')
      },
    },
    isButtonDisabled() {
      return this.loading || !this.isFormValid
    },
  },
  watch: {
    $route: {
      async handler() {
        this.branchOfficeLoading = true
        await this.fetchBranchOffices(this.company.id)
        if (this.$route.params.branchOfficeId !== 'new') {
          const branchOffice = this.$store.getters['branchOffice/branchOffice'](this.$route.params.branchOfficeId)
          if (!branchOffice) {
            alert('Diese Filiale konnte leider nicht gefunden werden.')
            return
          }
          this.$store.commit('branchOffice/setSelectedBranchOffice', branchOffice)
        } else {
          this.$store.commit('branchOffice/setSelectedBranchOffice', {
            opening_times: [],
            paymentMethods: {
              delivery: {
                paymentTypes: [],
                location_type: 1,
              },
              pick_up: {
                paymentTypes: [],
                location_type: 2,
              },
            },
            zips: [],
          })
        }
        this.branchOfficeLoading = false
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('branchOffice', ['fetchBranchOffices']),
    updateStreet(e) {
      this.$store.commit('branchOffice/setStreetForSelectedBranchOffice', e)
    },
    updateCity(e) {
      this.$store.commit('branchOffice/setCityForSelectedBranchOffice', e)
    },
    updateZip(e) {
      this.$store.commit('branchOffice/setZipForSelectedBranchOffice', e)
    },
    save() {
      if (!this.selectedBranchOffice.street
                || !this.selectedBranchOffice.zip
                || !this.selectedBranchOffice.city) {
        this.$toasted.error('Geben Sie bitte hier die Adresse der Filiale ein')
        return
      }

      const openingTimes = []
      if (this.currentWeekdays) {
        this.currentWeekdays.forEach((day, index) => {
          if (day.openingTimes) {
            day.openingTimes.forEach((item, dayTime) => {
              if (!item.begin || !item.end) {
                return
              }

              const tmp = {}
              tmp.day_of_week = index
              tmp.from = item.begin
              tmp.until = item.end
              tmp.dayTime = dayTime

              openingTimes.push(tmp)
            })
          }
        })
      }
      this.$store.commit('branchOffice/setOpeningHoursForSelectedBranchOffice', openingTimes)

      this.loading = true
      const apiCall = this.selectedBranchOffice.id > 0
        ? BranchOfficesAPI.update(this.company.id, this.selectedBranchOffice)
        : BranchOfficesAPI.create(this.company.id, this.selectedBranchOffice)

      apiCall.then(() => {
        this.$toasted.success('Die Filiale wurde erfolgreich gespeichert.')
        this.loading = false
        this.fetchBranchOffices(this.company.id)
      }).catch(() => {
        this.$toasted.error('Es ist ein Fehler aufgetreten. Die Filiale konnte nicht gespeichert werden.')
        this.loading = false
      })
    },
    goBack() {
      this.$router.replace({
        name: 'company.branchOffices',
        params: {
          id: this.company.id,
        },
      })
    },
  },
  components: {
    BranchOpeningTimes,
    RequiredFieldsLabel,
    BranchDeliveryCosts,
    BranchMap,
    BranchPaymentMethods,
  },
}
</script>
