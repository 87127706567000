<template>
  <v-row v-if="selectedBranchOffice.paymentMethods">
    <v-col
      cols="6"
      class="pt-0">
      <v-select
        :value="selectedBranchOffice.paymentMethods.delivery.paymentTypes"
        @change="updateDeliveryPaymentMethods"
        :items="$constants.PAYMENT_METHODS"
        item-text="TEXT"
        item-value="VALUE"
        label="Zahlungsmöglichkeiten bei Lieferung*"
        multiple
        :rules="[rules.required]"
      ></v-select>
    </v-col>
    <v-col
      cols="6"
      class="pt-0">
      <v-select
        :value="selectedBranchOffice.paymentMethods.pick_up.paymentTypes"
        @change="updatePickUpPaymentMethods"
        :items="$constants.PAYMENT_METHODS"
        item-text="TEXT"
        item-value="VALUE"
        label="Zahlungsmöglichkeiten im Fachgeschäft*"
        multiple
        :rules="[rules.required]"
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      paymentMethods: null,
      rules: {
        required: (value) => !!value.length || 'Erforderlich',
      },
    }
  },
  created() {
    if (this.selectedBranchOffice.paymentMethods === null) {
      this.createEmptyPaymentMethods()
    }
  },
  computed: {
    ...mapGetters({
      selectedBranchOffice: 'branchOffice/selectedBranchOffice',
    }),
  },
  methods: {
    updateDeliveryPaymentMethods(e) {
      this.$store.commit('branchOffice/setDeliveryPaymentMethodsForSelectedBranchOffice', e)
    },
    updatePickUpPaymentMethods(e) {
      this.$store.commit('branchOffice/setPickUpPaymentMethodsForSelectedBranchOffice', e)
    },
    createEmptyPaymentMethods() {
      const paymentMethods = {
        pick_up: {
          location_type: 2,
          paymentTypes: [],
        },
        delivery: {
          location_type: 1,
          paymentTypes: [],
        },
      }
      this.$store.commit('branchOffice/setPaymentMethodsForSelectedBranchOffice', paymentMethods)
    },
  },
}
</script>
