import CompaniesAPI from '@/api/companies'

export default {
  fetchCompanies({ commit }) {
    commit('setLoading', true)
    CompaniesAPI.getCompanies().then((response) => {
      commit('setCompanies', response)
      commit('setLoading', false)
    })
  },
}
