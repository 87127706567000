<template>
  <div v-if="order.branch_office">
    <div class="d-flex justify-space-between align-start">
      <h3 class="mt-0 text-primary">Abholung durch Kunden bei</h3>
      <CustomerAddressModal type="delivery">
        <v-btn>
          Bearbeiten
        </v-btn>
      </CustomerAddressModal>
    </div>
    <v-row>
      <v-col cols="12">
        {{ order.branch_office.street }}<br>
        {{ order.branch_office.zip }} {{ order.branch_office.city }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomerAddressModal from '@/components/orderDetails/CustomerAddressModal.vue'

export default {
  computed: {
    ...mapGetters({
      order: 'order/order',
    }),
  },
  components: {
    CustomerAddressModal,
  },
}
</script>
