<template>
  <div
    v-show="!loading"
    class="fullheight"
    id="mapbox-product">
  </div>
</template>

<script>
import loadScript from 'async-script'
import loadCSS from 'loadcss'
import MapsAPI from '@/api/maps'
import Vue from 'vue'

/* global mapboxgl */
export default {
  props: {
    street: {
      type: String,
      default: null,
      required: false,
    },
    city: {
      type: String,
      default: null,
      required: false,
    },
    zip: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      currentMarker: null,
      mapInstance: null,
      loading: false,
      lookedUpStreet: null,
      lookedUpZip: null,
      lookedUpCity: null,
    }
  },
  created() {
    this.loading = true
    Vue.nextTick(() => {
      if (typeof window.mapboxgl === 'undefined') {
        loadCSS('https://api.tiles.mapbox.com/mapbox-gl-js/v0.52.0/mapbox-gl.css')
        loadScript(document, 'https://api.tiles.mapbox.com/mapbox-gl-js/v0.52.0/mapbox-gl.js', this.buildMapbox)
      } else {
        this.buildMapbox()
      }
    })
  },
  watch: {
    street() {
      this.initMapPosition()
    },
    zip() {
      this.initMapPosition()
    },
    city() {
      this.initMapPosition()
    },
  },
  methods: {
    initMapPosition() {
      if (this.street
          && this.zip
          && this.city
          && (this.city !== this.lookedUpCity
          || this.zip !== this.lookedUpZip
          || this.street !== this.lookedUpCity)) {
        this.lookedUpStreet = this.street
        this.lookedUpCity = this.city
        this.lookedUpZip = this.zip

        MapsAPI.calculateCoordinates(this.street, this.zip, this.city)
          .then((response) => {
            this.mapInstance.setCenter([response.lng, response.lat])

            this.placeMarker(response.lng, response.lat)
            this.loading = false
          })
      }
    },
    buildMapbox() {
      Vue.nextTick(() => {
        mapboxgl.accessToken = 'pk.eyJ1IjoibW9yaXR1ciIsImEiOiJjanFyeHkxNjYwcHN1NDhtZDA1bm9odmhzIn0.Rne-1gNdSvUn9IrjlcyDhw'
        this.mapInstance = new mapboxgl.Map({
          container: 'mapbox-product',
          style: 'mapbox://styles/moritur/cjqrz2m6kjshc2rmwx2ijhz5q',
          center: [52.550756, 13.3888145, 15], // starting position
          zoom: 11, // starting zoom
        })
        this.mapInstance.on('load', () => {
          this.initMapPosition()
        })
      })
    },
    placeMarker(lng, lat) {
      if (this.currentMarker) {
        this.currentMarker.remove()
      }
      this.currentMarker = new mapboxgl.Marker()
        .setLngLat([lng, lat])
        .addTo(this.mapInstance)
    },
  },
}
</script>

<style lang="scss" scoped>
    .fullheight {
        height: 100%;
    }
</style>
