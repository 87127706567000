export default {
  setOrder(state, order) {
    state.order = order
  },
  setLoading(state, loading) {
    state.loading = loading
  },
  setReadyStatusModalIsOpen(state, value) {
    state.readyStatusModalIsOpen = value
  },
}
