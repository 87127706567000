<template>
  <div
    class="map__container "
    :class="{
      '--bigMap': bigMap,
      '--fullScreenMap': fullScreenMap,
    }">
    <div
      id='map'
      :class="{
        '--bigMap': bigMap,
        '--fullScreenMap': fullScreenMap,
      }"></div>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

export default {
  props: {
    value: {
      type: String,
    },
    center: {
      type: Array,
      required: false,
      default() {
        return [9.680845, 50.555809]
      },
    },
    zoom: {
      type: Number,
      required: false,
      default: 9,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    bigMap: {
      type: Boolean,
      required: false,
      default: false,
    },
    fullScreenMap: {
      type: Boolean,
      required: false,
      default: false,
    },
    branchOffices: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    hideLinkToCompany: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      map: null,
      zips: [],
      popupWithBranchOfficeIsOpen: false,
    }
  },
  mounted() {
    mapboxgl.accessToken = this.$constants.MAP_BOX.ACCESS_TOKEN
    this.map = new mapboxgl.Map({
      container: 'map', // container id
      style: 'mapbox://styles/moritur/cj1rjm8cw000v2ro0j1esfnne', // stylesheet location
      center: this.center, // starting position
      zoom: this.zoom, // starting zoom
    })
    this.map.on('load', () => {
      const availableZips = []
      const filter = ['in', 'plz']
      availableZips.forEach((zip) => {
        filter.push(zip)
      })
      this.map.setFilter('plz-5stellig-8bnume', filter)
      this.map.setPaintProperty('plz-5stellig-8bnume', 'fill-color', '#5777B5')

      this.map.addLayer({
        id: 'unavailable',
        source: {
          type: 'vector',
          url: 'mapbox://moritur.8mhsz5ya',
        },
        'source-layer': 'plz-5stellig-8bnume',
        type: 'fill',
        paint: {
          'fill-color': 'rgba(0, 0, 0, 0)',
          'fill-outline-color': '#000000',
        },
      })
      this.map.addLayer({
        id: 'newzips',
        source: {
          type: 'vector',
          url: 'mapbox://moritur.8mhsz5ya',
        },
        'source-layer': 'plz-5stellig-8bnume',
        type: 'fill',
        paint: {
          'fill-color': '#1976d2',
          'fill-outline-color': '#000000',
        },
      })
      this.setZipsToMap()

      if (this.branchOffices.length) {
        this.map.addLayer({
          id: 'company_zips',
          source: {
            type: 'vector',
            url: 'mapbox://moritur.8mhsz5ya',
          },
          'source-layer': 'plz-5stellig-8bnume',
          type: 'fill',
          paint: {
            'fill-color': '#6cb5fe',
            'fill-outline-color': '#000000',
          },
        })
        this.map.setFilter('company_zips', ['in', 'plz'])

        this.setBranchOfficesToMap()
      }

      filter[0] = '!in'
      this.map.setFilter('unavailable', filter)

      if (!this.readOnly) {
        this.map.on('click', 'unavailable', (e) => {
          const zip = e.features[0].properties.plz
          const pos = this.zips.indexOf(zip)
          if (pos === -1) {
            this.zips.push(zip)
          } else {
            this.zips.splice(pos, 1)
          }
          this.updateZips()
        })
      }

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      })

      this.map.on('mousemove', 'unavailable', (e) => {
        const isMarker = !!e.originalEvent.target.closest('.mapboxgl-marker')
        // Change the cursor style as a UI indicator.
        if (!this.readOnly) {
          this.map.getCanvas().style.cursor = 'pointer'
        }

        if (!isMarker && !this.popupWithBranchOfficeIsOpen) {
          // Populate the popup and set its coordinates
          // based on the feature found.
          popup.setLngLat(e.lngLat)
            .setHTML(`${e.features[0].properties.note}<br>${e.features[0].properties.einwohner} Einwohner`)
            .addTo(this.map)
        }
      })

      this.map.on('mouseleave', 'unavailable', () => {
        this.map.getCanvas().style.cursor = ''
        popup.remove()
      })

      this.map.on('mousemove', 'plz-5stellig-8bnume', (e) => {
        // Change the cursor style as a UI indicator.
        this.map.getCanvas().style.cursor = 'pointer'
        // Populate the popup and set its coordinates
        // based on the feature found.
        popup.setLngLat(e.lngLat)
          .setHTML(`${e.features[0].properties.note}<br>${e.features[0].properties.einwohner} Einwohner`)
          .addTo(this.map)
      })

      this.map.on('mouseleave', 'plz-5stellig-8bnume', () => {
        this.map.getCanvas().style.cursor = ''
        popup.remove()
      })
    })
  },
  destroyed() {
    this.map.remove()
  },
  watch: {
    value: {
      handler() {
        this.zips = this.value.split(',').filter((zip) => zip.length > 0)
      },
      immediate: true,
    },
    zips: {
      handler() {
        this.setZipsToMap()
      },
    },
  },
  methods: {
    updateZips() {
      this.$emit('input', this.zips.join(','))
    },
    setZipsToMap() {
      const newFilter = ['in', 'plz']
      this.zips.forEach((zip) => {
        newFilter.push((`${zip}`).trim())
      })
      this.map.setFilter('newzips', newFilter)
    },
    setBranchOfficesToMap() {
      this.branchOffices.forEach((branchOffice) => {
        // Only MH partners should be shown on the map
        if (!branchOffice.partner_mh) {
          return
        }
        let popupContent = `<p><b>${branchOffice.company_name}</b><br><br>${branchOffice.street},<br>${branchOffice.zip} ${branchOffice.city}</p>`
        if (!this.hideLinkToCompany) {
          popupContent += `<a href="/company-management/${branchOffice.company_id}/general">Partner verwalten</a>`
        }
        const popup = new mapboxgl.Popup().setHTML(popupContent).on('open', () => {
          const newFilter = ['in', 'plz']
          branchOffice.company_suppliable_zips.forEach((zip) => {
            newFilter.push((`${zip}`).trim())
          })
          this.map.setFilter('company_zips', newFilter)

          this.popupWithBranchOfficeIsOpen = true
        }).on('close', () => {
          this.popupWithBranchOfficeIsOpen = false
        })

        new mapboxgl.Marker({
          color: '#ff0000',
        }).setLngLat([branchOffice.lng, branchOffice.lat])
          .setPopup(popup)
          .addTo(this.map)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
body {
  margin: 0;
  padding: 0;
}

.map__container {
  width: 100%;
  min-height: 400px;
  position: relative;
  &.--bigMap{
     min-height: calc(100vh - 300px);
   }
  &.--fullScreenMap{
    min-height: calc(100vh - 50px);
  }
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 400px;
  &.--bigMap{
    min-height: calc(100vh - 300px);
  }
  &.--fullScreenMap{
    min-height: calc(100vh - 50px);
  }
}
</style>
