<template>
  <v-navigation-drawer
    id="app-drawer"
    v-model="inputValue"
    app
    dark
    floating
    mobile-breakpoint="991"
    persistent
    width="260"
  >
    <v-img
      height="100%"
    >
      <v-list
        class="fill-height"
      >
        <v-layout
          class="fill-height flex"
          justify-space-between
          column>
          <v-flex class="flex-grow-0">
            <v-flex class="s-header">
              <v-list-item>
                <v-list-item-title class="title mt-1">
                  MeinBackend
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-content :title="companyName">
                  <v-list-item-title class="subtitle-1">
                    {{ name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-flex>
            <v-divider/>
            <v-list-item
              v-for="(link, i) in relevantLinks"
              :key="i"
              :active-class="link.activeClass || color"
              :to="link.to"
              class="v-list-item"
            >
              <v-list-item-action>
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ link.text }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              active-class="primary"
              class="v-list-item v-list__tile--buy"
              href="https://meinhilfsmittel.de/backend"
              tag="a"
              v-if="isSuperAdmin"
            >
              <v-list-item-action>
                <v-icon>arrow_back</v-icon>
              </v-list-item-action>
              <v-list-item-title class="font-weight-light">
                Zum alten Backend
              </v-list-item-title>
            </v-list-item>
          </v-flex>
          <v-spacer/>
          <v-flex class="flex-grow-0">
            <v-list-item
              active-class="primary"
              class="v-list-item"
              to="/logout"
            >
              <v-list-item-action>
                <v-icon>logout</v-icon>
              </v-list-item-action>
              <v-list-item-title class="font-weight-light">
                Abmelden
              </v-list-item-title>
            </v-list-item>
          </v-flex>
        </v-layout>
      </v-list>
    </v-img>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import {
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex'
import store from '@/store'

export default {
  data: () => ({
    responsive: false,
  }),
  mounted() {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },
  computed: {
    ...mapState('app', ['image', 'color']),
    ...mapGetters({
      isSuperAdmin: 'auth/isSuperAdmin',
      partner_me: 'auth/partner_me',
      partner_mh: 'auth/partner_mh',
      name: 'auth/name',
      email: 'auth/email',
      companyName: 'auth/companyName',
      company_id: 'auth/company_id',
    }),
    links() {
      let companyUserLink = '/users/companies'

      if (!this.isSuperAdmin) {
        companyUserLink = `/users/companies/${this.company_id}`
      }

      return [
        {
          to: '/dashboard',
          icon: 'dashboard',
          text: 'Dashboard',
        },
        {
          to: '/orders',
          icon: 'schedule',
          text: 'Mietanfragen',
          onlyMH: true,
        },
        {
          to: '/appointments/consulting',
          icon: 'question_answer',
          text: 'Beratungsanfragen',
          onlyME: true,
        },
        {
          to: '/product-management',
          icon: 'moped',
          text: 'Produktverwaltung',
          onlySuperadmin: true,
        },
        {
          to: '/company-management',
          icon: 'supervisor_account',
          text: 'Partnerverwaltung',
          onlySuperadmin: true,
          activeClass: (this.$route.name && this.$route.name.startsWith('company.') && parseInt(this.$route.params.id, 10) === this.$store.state.auth.company_id) ? 'no-active-link' : this.color,
        },
        {
          to: '/reports/order-provision',
          icon: 'data_usage',
          text: 'Statistiken',
          onlySuperadmin: true,
        },
        {
          to: companyUserLink,
          icon: 'people',
          text: 'Mitarbeiter',
        },
        {
          to: `/company-management/${store.state.auth.company_id}`,
          icon: 'settings',
          text: 'Einstellungen',
        },
        {
          to: '/tools',
          icon: 'tune',
          text: 'Tools',
          onlySuperadmin: true,
        },
        {
          to: '/ratings',
          icon: 'star',
          text: 'Bewertungen',
          onlySuperadmin: true,
        },
        {
          to: '/faq',
          icon: 'help',
          text: 'Hilfe & FAQ',
        },
      ]
    },
    inputValue: {
      get() {
        return this.$store.state.app.drawer
      },
      set(val) {
        this.setDrawer(val)
      },
    },
    items() {
      return this.$t('Layout.View.items')
    },
    relevantLinks() {
      return this.links.filter((link) => {
        if (link.onlySuperadmin && !this.isSuperAdmin) {
          return false
        }
        if (link.onlyMH && !this.partner_mh) {
          return false
        }
        if (link.onlyME && !this.partner_me) {
          return false
        }
        return true
      })
    },
  },
  methods: {
    ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true
      } else {
        this.responsive = false
      }
    },
  },
}
</script>

<style lang="scss">
#app-drawer {
    .v-list__tile {
        border-radius: 4px;

        &--buy {
            margin-top: auto;
            margin-bottom: 17px;
        }
    }

    .v-image__image--contain {
        top: 9px;
        height: 60%;
    }

    .search-input {
        margin-bottom: 30px !important;
        padding-left: 15px;
        padding-right: 15px;
    }

    .no-active-link.theme--dark.v-list-item--active:hover::before, .no-active-link.theme--dark.v-list-item--active::before {
      opacity: 0;
    }

    .s-header {
      background: url('../../../public/img/bg-header.jpg')
    }
}

</style>
