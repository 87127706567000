<template>
  <v-app-bar
    app
    flat
  >
    <v-app-bar-nav-icon
      v-if="responsive"
      color="black"
      @click.stop="onClickBtn"/>
    <v-toolbar-title>
      {{ title }}
    </v-toolbar-title>

    <v-spacer/>
    <v-toolbar-items>
      <v-col
        class="align-center layout py-2"
      >
        <LiveSearch v-if="responsiveInput"/>
      </v-col>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>

import {
  mapMutations,
  mapGetters,
} from 'vuex'
import LiveSearch from '@/components/search/LiveSearch.vue'

export default {
  data: () => ({
    responsive: false,
    responsiveInput: false,
  }),
  mounted() {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },
  computed: {
    ...mapGetters({
      storeTitle: 'app/title',
    }),
    title() {
      return this.storeTitle
        ? this.storeTitle
        : this.$route.meta.name
    },
  },
  methods: {
    ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
    onClickBtn() {
      this.setDrawer(!this.$store.state.app.drawer)
    },
    onClick() {
      //
    },
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true
        this.responsiveInput = false
      } else {
        this.responsive = false
        this.responsiveInput = true
      }
    },
  },
  components: {
    LiveSearch,
  },
}
</script>

<style>
#core-toolbar a {
    text-decoration: none;
}
</style>
