<template>
  <div>
    <div class="c-orderLog__title">{{ getType() }}: <b>{{ log.title }}</b></div>
    <div class="grey--text c-orderLog__metadata my-1">{{ getUserName() }} | {{ log.updated_at | datetime }} </div>
    <div
      v-if="log.content && !showMore"
      class="c-orderLog__content"
      v-html="$options.filters.limitString(log.content, 200)" />
    <v-btn
      v-if="log.content.length > 200 && !showMore"
      class="px-0"
      text
      color="grey"
      @click="showMore = true">
      Mehr
    </v-btn>
    <div
      v-if="showMore"
      class="c-orderLog__content"
      v-html="log.content" />
  </div>
</template>

<script>

export default {
  props: {
    log: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showMore: false,
    }
  },
  methods: {
    getType() {
      switch (this.log.type) {
        case 'status':
          return 'Status'
        case 'email':
          return 'Email'
        default:
          return ''
      }
    },
    getUserName() {
      if (this.log.user_id && !this.log.user_name) {
        return 'Benutzer gelöscht'
      }
      if (!this.log.user_id && !this.log.user_name) {
        return 'System'
      }
      return this.log.user_name
    },
  },
}
</script>
