export default {
  setBranchOffices(state, branchOffices) {
    state.branchOffices = branchOffices
  },
  setSelectedBranchOffice(state, selectedBranchOffice) {
    state.selectedBranchOffice = selectedBranchOffice
  },
  setOpeningHoursForSelectedBranchOffice(state, openingTimes) {
    state.selectedBranchOffice.opening_times = openingTimes
  },
  setStreetForSelectedBranchOffice(state, street) {
    state.selectedBranchOffice.street = street
  },
  setCityForSelectedBranchOffice(state, city) {
    state.selectedBranchOffice.city = city
  },
  setZipForSelectedBranchOffice(state, zip) {
    state.selectedBranchOffice.zip = zip
  },
  setPaymentMethodsForSelectedBranchOffice(state, paymentMethods) {
    state.selectedBranchOffice.paymentMethods = paymentMethods
  },
  setDeliveryPaymentMethodsForSelectedBranchOffice(state, paymentTypes) {
    state.selectedBranchOffice.paymentMethods.delivery.paymentTypes = paymentTypes
  },
  setPickUpPaymentMethodsForSelectedBranchOffice(state, paymentTypes) {
    state.selectedBranchOffice.paymentMethods.pick_up.paymentTypes = paymentTypes
  },
  addEmptyZipRowInSelectedBranchOffice(state) {
    state.selectedBranchOffice.zips.push({
      id: -1 * (new Date()).getTime(),
      zip: '',
      delivery_price: '',
    })
  },
  removeZipInSelectedBranchOffice(state, zip) {
    state.selectedBranchOffice.zips = state.selectedBranchOffice.zips.filter((item) => item.id !== zip.id)
  },
  setZipCodesForSelectedBranchOffice(state, payload) {
    state.selectedBranchOffice.zips[payload.index].zip = payload.zip
  },
  setZipPriceForSelectedBranchOffice(state, payload) {
    state.selectedBranchOffice.zips[payload.index].delivery_price = payload.delivery_price
  },
  setDeliveryPriceKmForSelectedBranchOffice(state, delivery_price_km) {
    state.selectedBranchOffice.delivery_price_km = delivery_price_km
  },
  setLoading(state, loading) {
    state.loading = loading
  },
}
