<template>
  <div>
    <h3 class="mt-0 text-primary">Verlauf</h3>
    <v-divider
      class="c-contentCard__divider -fullWidth -height-2 mt-4"/>
    <div
      v-for="(log, key) in order.logs"
      :key="`log-${log.type}-${key}`"
    >
      <Log
        class="mt-4"
        :log="log"/>
      <v-divider
        v-if="log !== order.logs.slice(-1)[0]"
        class="c-contentCard__divider -fullWidth -height-2 mt-4"/>
    </div>
    <div
      v-if="!order.logs.length && !order.storno"
      class="mt-4">
      Empty
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Log from '@/components/orderDetails/Log.vue'

export default {
  computed: {
    ...mapGetters({
      order: 'order/order',
    }),
  },
  components: {
    Log,
  },
}
</script>
