export default {
  token: null,
  isSuperAdmin: false,
  partner_me: null,
  partner_mh: null,
  companyName: null,
  company_id: null,
  name: null,
  email: null,
  loading: false,
}
