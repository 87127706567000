<template>
  <div>
    <div class="grey--text">{{ note.user_name }} | {{ note.created_at | datetime }} </div>
    <div
      v-html="note.text"
      style="white-space: pre-wrap"/>
  </div>
</template>

<script>

export default {
  props: {
    note: {
      type: Object,
      required: true,
    },
  },
}
</script>
