import router from '@/router'

const base = {
  lastErrorShown: null,
  store: null,
}
if (!process.client) {
  // fetch = require('node-fetch')
}
const fetch = window.fetch

export default base

base.get = function (urlPath, params = {}) {
  const headers = this.getDefaultHeaders()
  const url = new URL(base.getURL(urlPath))
  url.search = new URLSearchParams(params).toString()
  return new Promise((resolve, reject) => {
    fetch(url.toString(), {
      method: 'GET',
      headers,
    }).then(
      (response) => {
        if (!response.ok) {
          this.handleResponseError(response)
          reject(response)
          return
        }
        response
          .json()
          .then((result) => {
            if (result.meta) {
              this.handleResultMeta(result.meta)
            }
            resolve(result.data)
          })
          .catch(reject)
      },
      (error) => {
        this.handleConnectionError(error)
        reject(error)
      },
    )
  })
}

base.getFile = function (url) {
  const headers = this.getDefaultHeaders()
  return new Promise((resolve, reject) => {
    fetch(base.getURL(url), {
      method: 'GET',
      headers,
    }).then(
      (response) => {
        if (!response.ok) {
          this.handleResponseError(response)
          reject(response)
          return
        }
        response
          .blob()
          .then((result) => {
            resolve(result)
          })
          .catch(reject)
      },
      (error) => {
        this.handleConnectionError(error)
        reject(error)
      },
    )
  })
}

base.getFileWithParams = function (url, body) {
  const headers = this.getDefaultHeaders()
  headers['Content-Type'] = 'application/json'
  return new Promise((resolve, reject) => {
    fetch(base.getURL(url), {
      method: 'POST',
      body: JSON.stringify(body),
      headers,
    }).then(
      (response) => {
        if (!response.ok) {
          this.handleResponseError(response)
          reject(response)
          return
        }
        response
          .blob()
          .then((result) => {
            resolve(result)
          })
          .catch(reject)
      },
      (error) => {
        this.handleConnectionError(error)
        reject(error)
      },
    )
  })
}

base.post = function (url, body, suppressErrors = false) {
  const headers = this.getDefaultHeaders()
  headers['Content-Type'] = 'application/json'
  return new Promise((resolve, reject) => {
    fetch(base.getURL(url), {
      method: 'POST',
      body: JSON.stringify(body),
      headers,
    }).then(
      (response) => {
        if (!response.ok) {
          if (!suppressErrors) {
            this.handleResponseError(response)
          }
          reject(response)
          return
        }
        response
          .json()
          .then((result) => {
            if (result.meta) {
              this.handleResultMeta(result.meta)
            }
            resolve(result.data)
          })
          .catch(reject)
      },
      (error) => {
        this.handleConnectionError(error)
        reject(error)
      },
    )
  })
}

base.put = function (url, body) {
  const headers = this.getDefaultHeaders()
  headers['Content-Type'] = 'application/json'
  return new Promise((resolve, reject) => {
    fetch(base.getURL(url), {
      method: 'PUT',
      body: JSON.stringify(body),
      headers,
    }).then(
      (response) => {
        if (!response.ok) {
          this.handleResponseError(response)
          reject(response)
          return
        }
        response
          .json()
          .then((result) => {
            if (result.meta) {
              this.handleResultMeta(result.meta)
            }
            resolve(result.data)
          })
          .catch(reject)
      },
      (error) => {
        this.handleConnectionError(error)
        reject(error)
      },
    )
  })
}

base.delete = function (url, body) {
  const headers = this.getDefaultHeaders()
  headers['Content-Type'] = 'application/json'
  return new Promise((resolve, reject) => {
    fetch(base.getURL(url), {
      method: 'DELETE',
      body: JSON.stringify(body),
      headers,
    }).then(
      (response) => {
        if (!response.ok) {
          this.handleResponseError(response)
          reject(response)
          return
        }
        response
          .json()
          .then((result) => {
            if (result.meta) {
              this.handleResultMeta(result.meta)
            }
            resolve(result.data)
          })
          .catch(reject)
      },
      (error) => {
        this.handleConnectionError(error)
        reject(error)
      },
    )
  })
}

/**
 * Sends the file
 *
 * @param url
 * @param body
 * @param suppressErrors
 * @returns {Promise<unknown>}
 */
base.sendFile = function (url, body, suppressErrors = false) {
  const headers = this.getDefaultHeaders()
  return new Promise((resolve, reject) => {
    fetch(base.getURL(url), {
      method: 'POST',
      body,
      headers,
    }).then(
      (response) => {
        if (!response.ok) {
          if (!suppressErrors) {
            this.handleResponseError(response)
          }
          reject(response)
          return
        }
        response
          .json()
          .then((result) => {
            if (result.meta) {
              this.handleResultMeta(result.meta)
            }
            resolve(result.data)
          })
          .catch(reject)
      },
      (error) => {
        this.handleConnectionError(error)
        reject(error)
      },
    )
  })
}

base.handleResultMeta = function (meta) {
  if (meta.token) {
    this.setToken(meta.token)
  }
}

base.handleResponseError = function (response) {
  // eslint-disable-next-line no-console
  if (response.status === 401) {
    if (response.headers.get('x-logout-user')) {
      this.store.dispatch('auth/logout').then(() => {
        alert('Bitte melden Sie sich erneut an.')
        router.push({ name: 'login' })
      })
    } else {
      alert('Sie müssen ein Administrator sein, um diese Seite aufrufen zu können.')
    }
    // TODO: log out user locally
  }
  // TODO: display error
}

base.handleConnectionError = function (error) {
  // eslint-disable-next-line no-console
  console.log(`Connection Error: ${error.message}`)
  // TODO: display error
}

/**
 * Returns the absolute URL to an API endpoint
 *
 * @param string path
 */
base.getURL = function (path) {
  return process.env.VUE_APP_API_BASE + path
}

/**
 * Returns the default headers
 *
 * @returns {*}
 */
base.getDefaultHeaders = function () {
  const headers = {
    Accept: 'application/json',
  }
  if (this.getToken()) {
    headers.Authorization = `Bearer ${this.getToken()}`
  }
  return headers
}

/**
 * Sets the store instance
 *
 * @param {*} store
 */
base.setStore = function (store) {
  this.store = store
}

/**
 * Get the API token
 *
 * @returns {*}
 */
base.getToken = function () {
  return this.store.getters['auth/token']
}

/**
 * Sets the API token
 *
 * @param string token
 */
base.setToken = function (token) {
  if (!process.browser) {
    return null
  }
  return this.store.commit('auth/setToken', token)
}
