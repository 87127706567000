export default {
  primary: '#1f6bd4',
  secondary: '#48c5d9',
  tertiary: '#9c9c9c',
  accent: '#2a7ae8',
  error: '#db4836',
  info: '#00d3ee',
  success: '#5cb860',
  warning: '#ffa21a',
}
