<template>
  <material-card color="primary">
    <template v-slot:header>
      <v-container class="py-0">
        <v-row
          justify="space-between">
          <h4
            class="title font-weight-light mb-2">
            Neue Mietanfragen
          </h4>
          <router-link
            class="px-4 mr-n4"
            to="/orders">
            <v-icon
              small
              color="white">
              arrow_forward
            </v-icon>
          </router-link>
        </v-row>
      </v-container>
    </template>
    <template v-if="loading">
      <v-col
        class="text-center"
        v-if="loading"
        cols="12">
        <v-progress-circular
          color="primary"
          indeterminate/>
      </v-col>
    </template>
    <template v-else>
      <v-alert
        v-if="orders.length === 0"
        color="info"
        text>
        Aktuell gibt es keine neuen Mietanfragen
      </v-alert>
      <Orders
        v-else
        :orders="orders"
      />
    </template>
  </material-card>
</template>

<script>
import Orders from './Orders.vue'

export default {
  props: {
    orders: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Orders,
  },
}
</script>
<style lang="scss">
@import "src/styles/material-dashboard/variables";
.v-card .v-offset .v-card--material__header.v-card {
  padding: 0 $padding-general;
  h4, a{
    padding: $padding-general 0;
  }
}
</style>
