import UsersAPI from '@/api/users'

export default {
  fetchUsers({ commit }, companyId) {
    commit('setLoading', true)
    UsersAPI.getUsers(companyId).then((response) => {
      commit('setUsers', response)
      commit('setLoading', false)
    })
  },
}
