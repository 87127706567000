<template>
  <v-row>
    <v-col cols="3">
      <div class="px-4 c-contentCard__title -small pb-1 font-weight-regular">Partner</div>
      <div class="px-4 c-contentCard__text -big">
        {{  order.company.name }}
      </div>
    </v-col>
    <v-col cols="3">
      <div class="px-4 c-contentCard__title -small  pb-1 font-weight-regular">RUSSKA Kdnr</div>
      <div class="px-4 c-contentCard__text -big">
        {{  order.company.russka_customernr }}
      </div>
    </v-col>
    <v-col cols="3">
      <div class="px-4 c-contentCard__title -small  pb-1 font-weight-regular">Ansprechpartner</div>
      <div class="px-4 c-contentCard__text -big">
        {{  order.company.contact_name }}
      </div>
    </v-col>
    <v-col cols="3">
      <div class="px-4 c-contentCard__title -small  pb-1 font-weight-regular">Telefon</div>
      <div class="px-4 c-contentCard__text -big">
        {{  order.company.contact_tel }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      order: 'order/order',
    }),
    delivery() {
      if (this.order.delivery === this.$constants.DELIVERY_TYPES.own.VALUE) {
        return {
          text: this.$constants.SUBDELIVERY_TYPES[this.order.subdelivery].TEXT,
          icon: this.$constants.SUBDELIVERY_TYPES[this.order.subdelivery].ICON,
        }
      }
      return {
        text: this.$constants.DELIVERY_TYPES[this.order.delivery].TEXT,
        icon: this.$constants.DELIVERY_TYPES[this.order.delivery].ICON,
      }
    },
  },
}
</script>
