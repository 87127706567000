import base from '@/api/base'

export default {
  calculateCoordinates(street, zip, city) {
    return base.post('/me/backend/maps/calculate', {
      street,
      zip,
      city,
    })
  },
}
