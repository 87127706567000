import ProductsAPI from '@/api/products'

export default {
  fetchRentProducts({ commit, state }) {
    if (!state.rentProducts.length) {
      commit('setLoading', true)
      ProductsAPI.getRentProducts().then((response) => {
        commit('setRentProducts', response)
        commit('setLoading', false)
      })
    }
  },
}
