<template>
  <v-dialog
    v-model="dialog"
    width="500">
    <template v-slot:activator="{ on }">
      <v-btn
        dark
        color="success"
        v-on="on">
        <slot></slot>
      </v-btn>
    </template>

    <v-form @submit.prevent="save">
      <v-card>
        <v-card-title
          class="headline grey lighten-2">
          Neue Notiz hinzufügen
        </v-card-title>
        <v-card-text>
          <v-textarea
            autofocus
            outlined
            auto-grow
            v-model="text"
            label="Notiz">
          </v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            type="submit"
            color="success"
            :loading="loading"
            :disabled="loading">
            Speichern
          </v-btn>
          <v-btn
            type="button"
            color="error"
            @click="dialog = false"
            :loading="loading"
            :disabled="loading">
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import NotesAPI from '@/api/notes'

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    foreignId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      dialog: false,
      text: null,
    }
  },
  methods: {
    save() {
      if (!this.text || this.text.trim().length < 1) {
        this.$toasted.error('Das Notizfeld muss ausgefüllt sein')
        return
      }

      this.loading = true
      NotesAPI.create(this.type, this.foreignId, this.text).then(() => {
        this.loading = false
        this.dialog = false
        this.$emit('success')
      })
    },
  },
}
</script>

<style lang="scss" scoped>
    textarea {
        margin-top: 0;
    }
</style>
