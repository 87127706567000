<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="500"
    content-class="s-dialogOverflow">
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <slot />
      </span>
    </template>
    <v-form @submit.prevent="save">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Lieferzeit
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="appointment.timerange"
                :items="timerangeTypes"
                item-text="text"
                item-value="value"
                label="Lieferzeitraum" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-menu
                v-model="menuDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Datum"
                    prepend-icon="calendar_today"
                    readonly
                    :value="formattedDate"
                    v-bind="attrs"
                    v-on="on" />
                </template>
                <v-date-picker
                  v-model="appointment.date"
                  :first-day-of-week="1"
                  locale="de-de"
                  @input="menuDate = false" />
              </v-menu>
            </v-col>
            <v-col cols="6">
              <TimePicker
                v-show="appointment.timerange == null"
                v-model="appointment.time"
                hide-clear-button
                :minute-interval="15" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            :disabled="isButtonDisabled"
            :loading="loading"
            type="submit">
            Speichern
          </v-btn>
          <v-btn @click="dialog = false">
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'

import OrdersAPI from '@/api/orders'
import TimePicker from '@/components/core/TimePicker.vue'

export default {
  data() {
    return {
      allowedStep: (m) => m % 15 === 0,
      appointment: {},
      dialog: false,
      loading: false,
      menuDate: false,
      menuTime: false,
      timerangeTypes: [
        {
          text: 'Exakte Uhrzeit',
          value: null,
        },
        {
          text: 'im Verlauf des Tages',
          value: 1,
        },
        {
          text: 'Vormittags',
          value: 2,
        },
        {
          text: 'Nachmittags',
          value: 3,
        },
      ],
    }
  },
  created() {
    this.appointment = {
      date: this.order.appointment ? moment(this.order.appointment).format('YYYY-MM-DD') : this.getFirstProductDate(),
      time: this.order.appointment ? moment(this.order.appointment).format('HH:mm') : '10:00',
      timerange: this.order.appointment_timerange,
    }
  },
  computed: {
    ...mapGetters({
      order: 'order/order',
    }),
    combinedAppointment() {
      return `${this.appointment.date} ${this.appointment.time}`
    },
    formattedDate() {
      if (!this.appointment.date) {
        return ''
      }
      return moment(this.appointment.date, 'YYYY-MM-DD').format('DD.MM.YYYY')
    },
    formattedTime() {
      switch (this.appointment.timerange) {
        case 1:
          return 'im Laufe des Tages'
        case 2:
          return 'vormittags'
        case 3:
          return 'nachmittags'
      }
      return this.appointment.time
    },
    isButtonDisabled() {
      return this.loading || !this.isFormValid
    },
    isFormValid() {
      if (!this.appointment.date) {
        return false
      }
      if (this.appointment.timerange == null && !this.appointment.time) {
        return false
      }
      return true
    },
  },
  methods: {
    ...mapActions('order', ['fetchOrder']),
    getFirstProductDate() {
      if (!this.order || !this.order.orderproducts || !this.order.orderproducts.length) {
        return null
      }
      return moment(this.order.orderproducts.map((orderproduct) => orderproduct.start).sort()[0]).format('YYYY-MM-DD')
    },
    open() {
      this.dialog = true
    },
    save() {
      this.loading = true
      OrdersAPI.updateAppointment(this.order.id, {
        appointment: this.combinedAppointment,
        appointment_timerange: this.appointment.timerange,
      }).then(() => {
        this.$toasted.success('Die Lieferzeit wurde aktualisiert')
        this.fetchOrder(this.$route.params.id).then(() => {
          this.dialog = false
          this.$emit('saved')
        })
      }).catch(() => {
        this.$toasted.error('Hier stimmt etwas nicht. Bitte versuchen Sie es später erneut.')
      }).finally(() => {
        this.loading = false
      })
    },
  },
  components: {
    TimePicker,
  },
}
</script>
