<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="500">
    <template v-slot:activator="{ on }">
      <v-btn
        x-small
        v-on="on">
        <slot></slot>
      </v-btn>
    </template>

    <v-form
      v-model="isFormValid"
      @submit.prevent="remove">
      <v-card>
        <v-card-title
          class="headline grey lighten-2">
          Produkt löschen
        </v-card-title>
        <v-card-text>
          Möchten Sie dieses Produkt wirklich aus dem Auftrag entfernen?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :loading="loading"
            type="submit">
            Ja, Produkt entfernen
          </v-btn>
          <v-btn
            @click="dialog = false">
            Nein
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import OrdersAPI from '@/api/orders'

export default {
  props: {
    orderId: {
      type: Number,
      required: true,
    },
    orderProductId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isFormValid: false,
      loading: false,
      dialog: false,
    }
  },
  methods: {
    ...mapActions('order', ['fetchOrder']),
    remove() {
      OrdersAPI.deleteOrderProduct(this.orderId, this.orderProductId).then(() => {
        this.dialog = false
        this.fetchOrder(this.$route.params.id)
      }).catch((response) => {
        response
          .json()
          .then((result) => {
            if (result[0] !== undefined && result[0].code !== undefined) {
              this.$toasted.error(result[0].code)
            } else {
              this.$toasted.error('Hier stimmt etwas nicht. Bitte versuchen Sie es später erneut.')
            }
          })
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
