<template>
  <v-card>
    <v-card-text class="pt-0 pb-8">
      <v-row>
        <v-col
          cols="12"
          class="pt-0">
          <v-img
            v-if="company.logo"
            :src="company.logo"
            max-width="250"
          />
          <v-text-field
            label="Wählen Sie ein Logo"
            @click='pickFile'
            v-model='fileName'
            prepend-icon="attach_file" />
          <!-- Hidden -->
          <input
            type="file"
            style="display: none"
            ref="fileInput"
            accept="*/*"
            @change="flePicked">
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="pt-0">
          <v-text-field
            label="Slogan"
            v-model="companyData.slogan"
          />
        </v-col>
      </v-row>
      <v-card class="s-bottomActionCard">
        <v-card-text>
          <v-layout>
            <v-flex shrink>
              <v-btn
                color="success"
                @click.stop="onUploadSelectedFileClick"
                :loading="loading">
                Speichern
              </v-btn>
            </v-flex>
            <v-spacer/>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import CompanyAPI from '@/api/companies'
import dirtyState from '@/mixins/dirtyState'

export default {
  mixins: [dirtyState],
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fileName: '',
      fileObject: null,
      companyData: {},
      loading: false,
    }
  },
  watch: {
    company: {
      handler() {
        this.companyData = { ...this.company }
        this.dirtyStateWatch('companyData')
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    pickFile() {
      this.$refs.fileInput.click()
    },
    flePicked(event) {
      const files = event.target.files
      if (files[0] === undefined) {
        this.fileObject = null
        return
      }

      // Check validity of file
      if (files[0].name.lastIndexOf('.') <= 0) {
        return
      }

      const fr = new FileReader()
      fr.readAsDataURL(files[0])
      fr.addEventListener('load', () => {
        this.fileName = files[0].name
        this.fileObject = files[0]
        this.dirtyStateSet()
      })
    },
    onUploadSelectedFileClick() {
      this.loading = true

      const data = new FormData()
      data.append('slogan', this.companyData.slogan)

      if (this.fileObject) {
        data.append('logo', this.fileObject)
      }

      CompanyAPI.updateLogoAndSlogan(this.company.id, data).then(() => {
        this.$toasted.success('Die Daten wurden gespeichert.')
        this.dirtyStateUnset()
        this.$emit('update')
      }).catch((response) => {
        response
          .json()
          .then((result) => {
            if (result[0] !== undefined && result[0].code !== undefined) {
              this.$toasted.error(result[0].code)
            } else {
              this.$toasted.error('Die Daten konnten leider nicht gespeichert werden. Bitte versuchen Sie es später erneut.')
            }
          })
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
