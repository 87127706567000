import base from '@/api/base'

export default {
  create(companyId, model) {
    return base.post(`/me/backend/companies/${companyId}/branch_offices`, model)
  },
  update(companyId, model) {
    return base.post(`/me/backend/companies/${companyId}/branch_offices/${model.id}`, model)
  },
  getBranchOfficesByCompanyId(companyId) {
    return base.get(`/me/backend/companies/${companyId}/branch_offices`)
  },
  getAllBranchOffices() {
    return base.get('/me/backend/branch-offices')
  },
}
