<template>
  <div>
    <div class="d-flex justify-space-between align-start">
      <h3 class="mt-0 text-primary">Lieferzeit</h3>
      <AppointmentTimeModal>
        <v-btn>
          Bearbeiten
        </v-btn>
      </AppointmentTimeModal>
    </div>
    <v-row>
      <v-col cols="12">
        <template v-if="order.appointment">
          {{ order.appointment | date }}, {{ appointmentTime }}
        </template>
        <template v-else>
          <em>Noch kein Termin festgelegt</em>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AppointmentTimeModal from '@/components/orderDetails/AppointmentTimeModal.vue'

export default {
  computed: {
    ...mapGetters({
      order: 'order/order',
    }),
    appointmentTime() {
      if (!this.order) {
        return ''
      }
      switch (this.order.appointment_timerange) {
        case null:
          return this.$options.filters.time(this.order.appointment)
        case 1:
          return 'im Verlauf des Tages'
        case 2:
          return 'vormittags'
        case 3:
          return 'nachmittags'
      }
      return 'ungültige Eingabe'
    },
  },
  components: {
    AppointmentTimeModal,
  },
}
</script>
