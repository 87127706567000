<template>
  <div style="height: 100%;">
    <v-btn
      v-if="order.storno"
      class="c-cancellationButton"
      :loading="loading"
      @click="restoreOrder">
      Stornierung aufheben
    </v-btn>
    <CancellationModal v-else>
      <v-btn
        class="c-cancellationButton"
        outlined
        color="red">
        Stornieren
      </v-btn>
    </CancellationModal>
  </div>
</template>

<script>
import OrdersAPI from '@/api/orders'
import { mapActions, mapGetters } from 'vuex'
import CancellationModal from '@/components/orderDetails/CancellationModal.vue'

export default {
  data() {
    return {
      cancel: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      order: 'order/order',
    }),
  },
  methods: {
    ...mapActions('order', ['fetchOrder']),
    restoreOrder() {
      this.cancel = false

      this.loading = true
      OrdersAPI.updateStorno(this.order.id, { cancel: this.cancel }).then(() => {
        this.fetchOrder(this.$route.params.id)
      }).catch(() => {
        this.$toasted.error('Die Stornierung konnte leider nicht aufgehoben werden. Bitte wenden Sie sich an den Support.')
      }).finally(() => {
        this.loading = false
      })
    },
  },
  components: {
    CancellationModal,
  },
}
</script>
<style>
.c-cancellationButton {
    height: calc(100% - 8px) !important;
}
</style>
