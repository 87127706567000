<template>
  <div v-if="!isEmpty">
    <div class="d-flex justify-space-between align-start">
      <h3 class="mt-0 text-primary">{{ headerText }}</h3>
      <CustomerAddressModal :type="type">
        <v-btn>
          Bearbeiten
        </v-btn>
      </CustomerAddressModal>
    </div>
    <v-row>
      <v-col cols="12">
        <span v-if="address.companyname">{{ address.companyname }}<br></span>
        {{ address.firstname }} {{ address.lastname }}<br>
        {{ address.street }}<br>
        {{ address.zip }} {{ address.city }}<br>
        Tel.: {{ address.phone }}<br>
        E-Mail: {{ address.email }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomerAddressModal from '@/components/orderDetails/CustomerAddressModal.vue'

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      order: 'order/order',
    }),
    address() {
      if (this.type === 'billing') {
        return {
          companyname: this.order.b_companyname,
          firstname: this.order.b_firstname,
          lastname: this.order.b_lastname,
          street: this.order.b_street,
          zip: this.order.b_zip,
          city: this.order.b_city,
          phone: this.order.b_phone,
          email: this.order.b_email,
        }
      }
      return {
        companyname: this.order.companyname,
        firstname: this.order.firstname,
        lastname: this.order.lastname,
        street: this.order.street,
        zip: this.order.zip,
        city: this.order.city,
        phone: this.order.phone,
        email: this.order.email,
      }
    },
    isEmpty() {
      if (
        !this.address.companyname
                && !this.address.firstname
                && !this.address.lastname
                && !this.address.street
                && !this.address.zip
                && !this.address.city
                && !this.address.phone
                && !this.address.email
      ) {
        return true
      }
      return false
    },
    headerText() {
      if (this.type === 'billing') {
        return 'Rechnungsadresse'
      }
      return 'Lieferadresse'
    },
  },
  components: {
    CustomerAddressModal,
  },
}
</script>
