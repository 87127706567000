<template>
  <v-card v-if="isSuperAdmin">
    <v-card-text class="pt-4">
      <template v-if="zipLoading || branchOfficeLoading">
        <div class="text-center" >
          <v-progress-circular
            indeterminate
            color="primary"
          />
        </div>
      </template>
      <Mapbox
        v-else
        v-model="zips"
        :branch-offices="branchOffices"
        read-only
        big-map
        :zoom="6"/>
    </v-card-text>
  </v-card>
</template>

<script>
import CompaniesAPI from '@/api/companies'
import BranchOfficesAPI from '@/api/branchOffices'
import Mapbox from '../../Mapbox.vue'

export default {
  props: ['isSuperAdmin'],
  data() {
    return {
      zipLoading: false,
      branchOfficeLoading: false,
      zips: '',
      branchOffices: [],
    }
  },
  created() {
    this.loadZips()
    this.loadBranchOffices()
  },
  methods: {
    loadZips() {
      this.zipLoading = true
      CompaniesAPI.getAllSuppliableZips().then((response) => {
        this.zips = response.zips.join(',')
        this.zipLoading = false
      })
    },
    loadBranchOffices() {
      this.branchOfficeLoading = true
      BranchOfficesAPI.getAllBranchOffices().then((response) => {
        this.branchOffices = response
        this.branchOfficeLoading = false
      })
    },
  },
  components: {
    Mapbox,
  },
}
</script>
