<template>
  <div class="v-input theme--light v-text-field v-text-field--is-booted">
    <div
      v-if="icon"
      class="v-input__prepend-outer">
      <div class="v-input__icon v-input__icon--prepend">
        <v-icon>{{ icon }}</v-icon>
      </div>
    </div>
    <div class="v-input__control">
      <div class="v-input__slot">
        <div class="v-text-field__slot">
          <label
            v-if="title"
            class="v-label v-label--active theme--light"
            style="left: 0px; right: auto; position: absolute;">
            {{ title }}
          </label>
          <VueTimepicker
            v-model="model"
            v-bind="$attrs"
            close-on-complete
            hour-label="Stunde"
            manual-input
            minute-label="Minute"
            class="s-timepicker" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker'

export default {
  props: {
    icon: {
      default: 'access_time',
      required: false,
      type: String,
    },
    title: {
      default: 'Uhrzeit',
      required: false,
      type: String,
    },
    value: {
      required: true,
      type: [Object, String],
    },
  },
  data() {
    return {
      model: '',
    }
  },
  watch: {
    model: {
      handler() {
        this.$emit('input', this.model)
      },
      deep: true,
    },
    value: {
      handler() {
        this.model = this.value || ''
      },
      deep: true,
      immediate: true,
    },
  },
  components: {
    VueTimepicker,
  },
}
</script>

<style lang="scss">
.s-timepicker {
  width: 100%;

  input.display-time {
    border: 0;
    padding-left: 0;
    width: 100%;
  }
}
</style>
