<template>
  <div>
    <div class="days">
      <div
        class="day"
        v-for="(day, idx) in days"
        :key="idx"
        :class="{selected: selectedDay === idx}"
        @click="selectDay(idx)">
        <span class="day__day">{{ day.dayMonth }}</span><br>
        <span class="day__weekday">{{ day.weekday }}</span><br>
        <span class="day__times">{{day.times.length - day.used.length}} Termin<template v-if="day.times.length !== 1">e</template></span>
      </div>
    </div>
    <h5 v-if="selectedDay">Uhrzeiten</h5>
    <div
      class="times u-marginTop"
      v-if="selectedDay">
      <div
        class="time"
        v-for="time in days[selectedDay].times"
        :key="time"
        :class="{selected: selectedTime === time, used: days[selectedDay].used.includes(time)}"
        @click="selectTime(time)">
        {{ time }} Uhr
      </div>
    </div>
  </div>
</template>

<script>
import deLocale from 'date-fns/locale/de'
import format from 'date-fns/format'
import parse from 'date-fns/parse'

export default {
  props: {
    availabilities: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      selectedDay: null,
      selectedTime: null,
    }
  },
  computed: {
    days() {
      const days = {}
      this.availabilities.forEach((availability) => {
        const day = availability.from.substr(0, 10)
        if (typeof days[day] === 'undefined') {
          const date = parse(availability.from)
          days[day] = {
            date: format(date, 'YYYY-MM-DD', {
              locale: deLocale,
            }),
            weekday: format(date, 'dddd', {
              locale: deLocale,
            }),
            dayMonth: format(date, 'DD.MM.', {
              locale: deLocale,
            }),
            times: [],
            used: [],
          }
        }
        days[day].times.push(availability.from.substr(11, 5))
        if (availability.used) {
          days[day].used.push(availability.from.substr(11, 5))
        }
      })
      return days
    },
  },
  methods: {
    selectDay(day) {
      this.selectedDay = day
      this.selectedTime = null
    },
    selectTime(time) {
      this.selectedTime = time
      this.$emit('selected', `${this.selectedDay} ${this.selectedTime}`)
    },
  },
}
</script>

<style lang="scss" scoped>
    $spacing-base: 32px;
    $spacing-tiny: $spacing-base * 0.25;
    $color-blue-medium: #1f6bd4;
    $color-gray-darker: #d6d6d6;
    $color-gray-darkest: #9c9c9c;
    $color-white: #ffffff;
    $color-blue-darker: #0b346d;
    $transition-base: 0.3s ease;
    $button-background: linear-gradient(90deg, #4392ff 0%, #2a7ae8 100%);
    $button-boxShadow-active: 0 0 15px 0 rgba($color-blue-darker, 0.3);

    .days {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: $spacing-tiny;
        grid-row-gap: $spacing-tiny;

        @media (max-width: 640px) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        @media (max-width: 500px) {
            grid-template-columns: 1fr 1fr;
        }
    }

    .day, .time {
        padding: $spacing-tiny;
        border: 1px solid $color-gray-darker;
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
        background: $color-white;
        transition: transform $transition-base, box-shadow $transition-base;

        &:hover {
            border-color: $color-blue-medium;
        }

        &.used {
            background: $color-gray-darker;
            border: 0;
            color: $color-gray-darkest;
            transform: scale(1.02);
        }

        &.selected {
            background: $button-background;
            border: 0;
            color: $color-white;
            box-shadow: $button-boxShadow-active;
            transform: scale(1.02);
        }
    }

    .times {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: $spacing-tiny;
        grid-row-gap: $spacing-tiny;

        @media (max-width: 640px) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        @media (max-width: 500px) {
            grid-template-columns: 1fr 1fr;
        }

    }
</style>
