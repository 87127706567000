<template>
  <v-card>
    <v-card-text class="pt-0">
      <BranchOffice :company="company" />
    </v-card-text>
  </v-card>
</template>

<script>
import BranchOffice from '@/components/company/BranchOffice.vue'

export default {
  props: {
    company: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
  },
  components: {
    BranchOffice,
  },
}
</script>
