export default {
  setRatings(state, ratings) {
    state.ratings = ratings
  },
  setRating(state, rating) {
    state.rating = rating
  },
  setLoading(state, loading) {
    state.loading = loading
  },
}
