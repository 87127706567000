<template>
  <v-form>
    <v-card>
      <v-card-text class="pt-0">
        <v-row>
          <v-col
            cols="12"
            class="pt-0">
            <h3>Feiertage</h3>
          </v-col>
          <v-col cols="6">
            <div>An den folgenden gesetzlichen Feiertagen ist automatisch keine Lieferung möglich:</div>
            <v-list>
              <v-list-item
                :key="key"
                v-for="(value, key) in holidays">
                {{ key | dayMonth }} - {{ value }}
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="6">
            <div>
              Desweiteren haben Sie die Möglichkeit, zusätzliche Tage anzugeben, an denen Sie geschlossen haben:
            </div>
            <v-col cols="6">
              <v-list dense>
                <v-list-item
                  :key="index"
                  v-for="(item, index) in company.holidays"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.date | date }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <ConfirmModal
                      @success="removeHoliday(item.id)"
                      message="Soll dieser Feiertag wirklich entfernt werden?">
                    </ConfirmModal>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
            <HolidayModal
              :company="company"
              @success="$emit('update')">
            </HolidayModal>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import CompanyAPI from '@/api/companies'
import ConfirmModal from '@/components/ConfirmModal.vue'
import HolidayModal from '@/components/company/HolidayModal.vue'

export default {
  props: {
    company: {
      type: Object,
      required: true,
    },
    holidays: {
      type: Object,
      required: false,
    },
  },
  methods: {
    removeHoliday(holidayId) {
      CompanyAPI.removeHoliday(this.company, holidayId).then(() => {
        this.$toasted.success('Der Feiertag wurde erfolgreich entfernt')
        this.$emit('update')
      }).catch(() => {
        this.$toasted.error('Es ist ein Fehler beim Löschen des Feiertags aufgetreten')
      })
    },
  },
  components: {
    ConfirmModal,
    HolidayModal,
  },
}
</script>
