<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      width="500">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          color="success">
          Neuen Tag hinzufügen
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">Neuer Feiertag</v-card-title>
        <v-card-text>
          <div class="mb-4">An welchem weiteren Tag hat ihr Unternehmen geschlossen?</div>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            min-width="290px"
            offset-y
            transition="scale-transition">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="holidayDate"
                v-on="on"
                label="Datum auswählen"
                prepend-icon="event"
                readonly
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              header-color="primary"
              @input="menu = false"
            />
          </v-menu>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            :loading="loading"
            color="success"
            type="button"
            @click="confirm">
            Speichern
          </v-btn>
          <v-btn
            color="error"
            @click="dialog = false">
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CompanyAPI from '@/api/companies'
import format from 'date-fns/format'

export default {
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      dialog: false,
      date: null,
      menu: null,
    }
  },
  computed: {
    holidayDate: {
      set(value) {
        this.date = format(value, 'YYYY-MM-DD')
      },
      get() {
        if (!this.date) {
          return this.date
        }
        return format(this.date, 'DD.MM.YYYY')
      },
    },
  },
  methods: {
    confirm() {
      if (!this.date) {
        this.$toasted.error('Bitte vergeben Sie ein Datum an dem ihr Unternehmen geschlossen ist')
        return
      }

      this.loading = true
      CompanyAPI.addHoliday(this.company, this.date).then(() => {
        this.$toasted.success('Der Feiertag wurde erfolgreich gespeichert')
        this.$emit('success')
        this.loading = false
      }).catch(() => {
        this.$toasted.error('Der Feiertag konnte nicht angelegt werden, da er bereits existiert')
        this.loading = false
      })
      this.dialog = false
    },
  },
}
</script>

<style lang="scss">
.v-date-picker-table .v-btn {
    color: black !important;
}
</style>
