import base from '@/api/base'

export default {
  login(model) {
    return base.post('/me/backend/login', model, true)
  },
  logout() {
    return base.post('/me/backend/logout')
  },
}
