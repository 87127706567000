import base from '@/api/base'

export default {
  getDuplicationUrl(orderId) {
    return base.get(`/me/backend/orders/${orderId}/duplication-url`)
  },
  getOrders(pagination) {
    return base.get('/me/backend/orders', pagination)
  },
  getOrder(id) {
    return base.get(`/me/backend/orders/${id}`)
  },
  getInvoice(id) {
    return base.getFile(`/me/backend/orders/${id}/invoice`)
  },
  getLeaseContract(id) {
    return base.getFile(`/me/backend/orders/${id}/lease-contract`)
  },
  updateDeliveryAddress(id, model) {
    return base.put(`/me/backend/orders/${id}/delivery-address`, model)
  },
  updateBillingAddress(id, model) {
    return base.put(`/me/backend/orders/${id}/billing-address`, model)
  },
  updateOrderProduct(id, orderProductId, model) {
    return base.put(`/me/backend/orders/${id}/orderproducts/${orderProductId}`, model)
  },
  storeOrderProduct(id, model) {
    return base.post(`/me/backend/orders/${id}/orderproducts`, model)
  },
  deleteOrderProduct(id, orderProductId) {
    return base.delete(`/me/backend/orders/${id}/orderproducts/${orderProductId}`)
  },
  setStatusReady(id, model) {
    return base.put(`/me/backend/orders/${id}/setreadystatus`, model)
  },
  setStatus(id, newStatus) {
    return base.put(`/me/backend/orders/${id}/setstatus/${newStatus}`)
  },
  updateAppointment(id, model) {
    return base.put(`/me/backend/orders/${id}/appointment`, model)
  },
  balancePayment(id) {
    return base.get(`/me/backend/orders/${id}/balance-payment`)
  },
  updateStorno(id, model) {
    return base.put(`/me/backend/orders/${id}/update-storno`, model)
  },
}
