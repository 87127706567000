<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <v-form @submit.prevent="save">
    <v-card>
      <v-card-text class="pt-0 pb-8">
        <v-row>
          <v-col
            cols="6"
            class="pt-0">
            <h3>Ansprechpartner</h3>
            <div>An wen können wir uns bei Rückfragen wenden?</div>
            <v-text-field
              label="Name*"
              v-model="companyData.contact_name"
            />
            <v-text-field
              label="Telefon*"
              v-model="companyData.contact_tel"
            />
            <v-text-field
              label="E-Mail*"
              v-model="companyData.contact_mail"
            />
          </v-col>
          <RequiredFieldsLabel/>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="s-bottomActionCard">
      <v-card-text>
        <v-layout>
          <v-flex shrink>
            <v-btn
              :loading="loading"
              color="success"
              type="submit">
              Speichern
            </v-btn>
          </v-flex>
          <v-spacer/>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import CompanyAPI from '@/api/companies'
import RequiredFieldsLabel from '@/components/company/RequiredFieldsLabel.vue'
import dirtyState from '@/mixins/dirtyState'

export default {
  mixins: [dirtyState],
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      companyData: null,
      loading: false,
    }
  },
  watch: {
    company: {
      handler() {
        this.companyData = { ...this.company }
        this.dirtyStateWatch('companyData')
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    save() {
      if (!this.companyData.contact_name
          || !this.companyData.contact_mail
          || !this.companyData.contact_tel) {
        this.$toasted.error('Bitte füllen Sie alle Daten des Ansprechpartners aus')
        return
      }

      this.loading = true
      CompanyAPI.updateContactData(this.companyData).then(() => {
        this.$toasted.success('Die Daten des Ansprechpartners wurden erfolgreich gespeichert.')
        this.loading = false
        this.dirtyStateUnset()
      })
    },
  },
  components: {
    RequiredFieldsLabel,
  },
}
</script>
