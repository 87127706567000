<template>
  <v-container fluid>
    <ReportToolbar
      class="white mx-0"
      @update="filterChanged" />
    <v-col
      cols="12"
      v-if="loading"
      class="text-center">
      <v-progress-circular
        indeterminate
        color="primary"
      />
    </v-col>
    <template v-else>
      <v-data-table
        :headers="headers"
        :items="items"
        item-key="index"
        hide-default-footer>
        <template v-slot:items="props">
          <td>{{ props.item | fullId }}</td>
          <td>{{ props.item.name }}</td>
          <td>{{ props.item.partner_name }}</td>
          <td>{{ props.item.type }}</td>
          <td v-if="props.item.type === 'Rückruf'">{{ props.item.status | recallStatus }}</td>
          <td v-else>{{ props.item.status | consultingAppointmentStatus }}</td>
          <td>{{ props.item.price | euro }}</td>
        </template>

        <template v-slot:footer>
          <div
            class="text-right grey lighten-3 pa-4">
            <strong>Summe: {{ currentSum | euro }}</strong>
          </div>
        </template>
      </v-data-table>
    </template>
  </v-container>
</template>

<script>
import ReportToolbar from '@/components/report/ReportToolbar.vue'
import ReportAPI from '@/api/reports'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      loading: false,
      items: [],
      headers: [
        {
          text: 'Id',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Name',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Partner',
          sortable: true,
          value: 'partner_name',
        },
        {
          text: 'Art',
          sortable: false,
        },
        {
          text: 'Status',
          sortable: true,
          value: 'status',
        },
        {
          text: 'Provision',
          sortable: true,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      isSuperAdmin: 'auth/isSuperAdmin',
    }),
    currentSum() {
      let sum = 0
      if (this.items) {
        this.items.forEach((item) => {
          sum += item.price
        })
      }
      return sum
    },
  },
  methods: {
    filterChanged(options) {
      this.loading = true
      ReportAPI.getConsultingAppointmentProvisionReport(options.from, options.until, options.partners).then((response) => {
        this.items = Object.values(response)
        this.loading = false
      })
    },
  },
  components: {
    ReportToolbar,
  },
}
</script>
