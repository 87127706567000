<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      width="500">
      <template v-slot:activator="{ on }">
        <v-btn
          v-if="showButtonToReactivate"
          v-on="on">
          Partner reaktivieren
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">Partner reaktivieren</v-card-title>
        <v-card-text>
          Möchten Sie den Partner wieder aktivieren? Dadurch wird er Kunden wieder angezeigt.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            :loading="loading"
            color="success"
            type="button"
            @click="confirm">
            Partner aktivieren
          </v-btn>
          <v-btn
            @click="dialog = false">
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CompanyAPI from '@/api/companies'

export default {
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      dialog: false,
      showButtonToReactivate: true,
    }
  },
  methods: {
    confirm() {
      this.loading = true
      CompanyAPI.activate(this.company).then(() => {
        this.$toasted.success(`${this.company.name} wurde reaktiviert`)
        this.showButtonToReactivate = false
        this.$emit('success')
      }).catch(() => {
        this.$toasted.error(`${this.company.name} konnte leider nicht reaktiviert werden. Bitte wenden Sie sich an den Support.`)
      }).finally(() => {
        this.loading = false
        this.dialog = false
      })
    },
  },
}
</script>
