import RatingsAPI from '@/api/ratings'

export default {
  fetchRatings({ commit }) {
    commit('setLoading', true)
    RatingsAPI.getRatings().then((response) => {
      commit('setRatings', response)
      commit('setLoading', false)
    })
  },
  fetchRating({ commit }, id) {
    commit('setLoading', true)
    RatingsAPI.getRating(id).then((response) => {
      commit('setRating', response)
      commit('setLoading', false)
    })
  },
}
